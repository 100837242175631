import styled from 'styled-components';

const Card = styled.div`
  display: grid;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => `${theme.sizes.shadow} ${theme.colors.shadow}`};
`;

export default Card;
