import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    white: '#FAFAFA',
    black: '#222324',
    shadow: 'rgba(194, 197, 204, 0.25)',
    error: '#B00020',
    warning: '#E1C844',
    primary: {
      dark: '#215253',
      base: '#275B5C',
      light: 'rgba(86, 142, 143, 0.5)',
    },
    gray: {
      dark: '#CED2D9',
      base: '#DADFE5',
      light: '#F7F7F7',
    },
    title: '#212121',
    text: '#424242',
    background: '#ffffff',
    contentBackground: '#FAFAFA',
    border: '#e8e8e8',
    page: '#F0F3F7',
  },
  fonts: {
    title: "'Source Sans Pro', sans-serif",
    text: "'Lato', sans-serif",
  },
  sizes: {
    title: '24px',
    subTitle: '18px',
    text: '14px',
    header: '26px',
    sideBar: '230px',
    borderRadius: '10px',
    shadow: '0px 1px 3px 1px',
  },
};

export default theme;
