import AddCatalystDTO from '@/dtos/catalysts/AddCatalystDTO';
import CatalystDTO from '@/dtos/catalysts/CatalystDTO';
import CatalystFilterDTO from '@/dtos/catalysts/CatalystFilterDTO';
import { PageDTO } from '@/dtos/generics';
import api from '@/services/api';

class CatalystServices {
  async list(filters: CatalystFilterDTO): Promise<PageDTO<CatalystDTO>> {
    const { data } = await api.get<PageDTO<CatalystDTO>>('/catalysts', {
      params: filters,
    });
    return data;
  }
  async find(catalystId: string): Promise<CatalystDTO> {
    const { data } = await api.get<CatalystDTO>(`/catalysts/${catalystId}`);
    return data;
  }
  async create(catalyst: AddCatalystDTO): Promise<CatalystDTO> {
    const { data } = await api.post<CatalystDTO>('/catalysts', catalyst);
    return data;
  }
  async update(catalyst: CatalystDTO): Promise<CatalystDTO> {
    const { data } = await api.put<CatalystDTO>(
      `/catalysts/${catalyst.id}`,
      catalyst
    );
    return data;
  }
}

const catalystServices = new CatalystServices();

export default catalystServices;
