import api from '@/services/api';
import UserDTO from '@/dtos/user/UserDTO';
import AuthenticateParamsDTO from '@/dtos/user/AuthenticateParamsDTO';
import AuthenticateResultDTO from '@/dtos/user/AuthenticateResultDTO';
import UserFiltersDTO from '@/dtos/user/UserFiltersDTO';
import UserListResultDTO from '@/dtos/user/UserListResultDTO';
import { AddUserDTO, ChangeActiveDTO, UpdateUserDTO } from '@/dtos/user';

class UserServices {
  setToken(token: string): void {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
  async authenticate(
    user: AuthenticateParamsDTO
  ): Promise<AuthenticateResultDTO> {
    const { data } = await api.post<AuthenticateResultDTO>('/sessions', user);
    return data;
  }
  async getProfile(): Promise<UserDTO> {
    const { data } = await api.get<UserDTO>('/profile');
    return data;
  }
  async updateProfile(user: UserDTO): Promise<UserDTO> {
    const { data } = await api.put<UserDTO>('/profile', user);
    return data;
  }
  async sendPasswordRecoveryLink(email: string): Promise<void> {
    await api.post('/recovery', { email });
  }
  async resendPasswordRecoveryLink(code: string): Promise<void> {
    await api.post('/activate', { code });
  }
  async recoverPassword(code: string, password: string): Promise<void> {
    await api.put('/recovery', { code, password });
  }
  async activeCode(code: string, password: string): Promise<void> {
    await api.put('/activate', { code, password });
  }
  async requestConfirmCode(code: string): Promise<void> {
    await api.post('/confirmation', { code });
  }
  async confirmCode(
    code: string,
    password: string
  ): Promise<AuthenticateResultDTO> {
    const { data } = await api.put<AuthenticateResultDTO>('/confirmation', {
      code,
      password,
    });
    return data;
  }
  async create(user: AddUserDTO): Promise<UserDTO> {
    const { data } = await api.post<UserDTO>('/users', user);
    return data;
  }
  async find(userId: string): Promise<UserDTO> {
    const { data } = await api.get<UserDTO>(`/users/${userId}`);
    return data;
  }
  async update(user: UpdateUserDTO): Promise<UserDTO> {
    const { data } = await api.put<UserDTO>(`/users/${user.id}`, user);
    return data;
  }
  async changeActive(params: ChangeActiveDTO): Promise<UserDTO> {
    const { data } = await api.put<UserDTO>(`/users/${params.id}`, params);
    return data;
  }
  async list(filters: UserFiltersDTO): Promise<UserListResultDTO> {
    const { data } = await api.get<UserListResultDTO>('/users', {
      params: filters,
    });
    return data;
  }
}

const userServices = new UserServices();
export default userServices;
