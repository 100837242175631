import AddEventDTO from '@/dtos/events/AddEventDTO';
import EventDTO from '@/dtos/events/EventDTO';
import EventErrorDTO from '@/dtos/events/EventErrorDTO';
import EventFilterDTO from '@/dtos/events/EventFilterDTO';
import api from '@/services/api';

class EventServices {
  async findByCode(code: string, filters: EventFilterDTO): Promise<EventDTO[]> {
    const { data } = await api.get<EventDTO[]>(`/codes/${code}/moviments`, {
      params: filters,
    });
    return data;
  }
  async create(events: AddEventDTO[]): Promise<EventErrorDTO[]> {
    const { data } = await api.post<EventErrorDTO[]>(
      '/codes/moviments',
      events
    );
    return data;
  }
  async breakdown(events: AddEventDTO[]): Promise<EventErrorDTO[]> {
    const { data } = await api.post<EventErrorDTO[]>(
      '/codes/moviments/breakdown',
      events
    );
    return data;
  }
}

const eventServices = new EventServices();

export default eventServices;
