import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ApplicationState } from '@/store/types';
import history from '@/services/history';

import Auth from './Auth';
import App from './App';
import { useTranslation } from 'react-i18next';

const Routes: React.FC = () => {
  const { t: translation } = useTranslation();
  const signed = useSelector((store: ApplicationState) => !!store.user.token);
  const RouteComponent = signed ? App : Auth;
  const tabTitle = translation('systemName');

  useEffect(() => {
    document.title = tabTitle;
  }, [tabTitle]);

  return (
    <Router history={history}>
      <RouteComponent />
    </Router>
  );
};

export default Routes;
