import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';
import userServices from '@/lib/users';
import history from '@/services/history';
import handleError from '@/adapters/httpErrors';
import UserListResultDTO from '@/dtos/user/UserListResultDTO';
import UserDTO from '@/dtos/user/UserDTO';
import UserFiltersDTO from '@/dtos/user/UserFiltersDTO';
import UsersActions, { UsersTypes } from './duck';

const registerUser = i18next.t('Success.successRegister');
const userUpdate = i18next.t('Success.successUpdate');

type AddUserParams = ReturnType<typeof UsersActions.addUserRequest>;
export function* addUser({ payload }: AddUserParams): Generator {
  try {
    yield call(userServices.create, payload.data);
    yield put(UsersActions.addUserSuccess());
    history.push('/users');
    toast.success(registerUser);
  } catch (err) {
    const error = handleError(err);
    yield put(UsersActions.addUserError(error));
  }
}

type GetUsersParams = ReturnType<typeof UsersActions.getUsersRequest>;
export function* getUsers({ payload }: GetUsersParams): Generator {
  try {
    const data = yield call(userServices.list, payload.filters);
    yield put(UsersActions.getUsersSuccess(data as UserListResultDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(UsersActions.getUsersError(error));
  }
}

// export function* getClients(action): Generator {
//   try {
//     const { filters } = action;
//     const response = yield call(api.get, `/consumers`, {
//       params: {
//         ...filters,
//         type: 'distributor',
//       },
//     });
//     const { data } = response;
//     yield put(UsersActions.getUsersSuccess(data));
//   } catch (err) {
//     const error = handleError(err);
//     yield put(UsersActions.getUsersError(error));
//   }
// }

type GetOneUserParams = ReturnType<typeof UsersActions.getOneUserRequest>;
export function* getOneUser({ payload }: GetOneUserParams): Generator {
  try {
    const data = yield call(userServices.find, payload.id);
    yield put(UsersActions.getOneUserSuccess(data as UserDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(UsersActions.getOneUserError(error));
  }
}

type UpdateUserParams = ReturnType<typeof UsersActions.updateRequest>;
export function* update({ payload }: UpdateUserParams): Generator {
  try {
    const data = yield call(userServices.update, payload);
    const user = data as UserDTO;
    yield put(UsersActions.updateSuccess());
    yield put(UsersActions.getUsersRequest({} as UserFiltersDTO));
    yield put(UsersActions.getOneUserRequest(user.id));
    toast.success(userUpdate);
  } catch (err) {
    const error = handleError(err);
    yield put(UsersActions.updateError(error));
  }
}

type ChangeActiveParams = ReturnType<typeof UsersActions.changeActiveRequest>;
export function* changeActive({ payload }: ChangeActiveParams): Generator {
  try {
    const data = yield call(userServices.changeActive, payload);
    const user = data as UserDTO;
    yield put(UsersActions.updateSuccess());
    yield put(UsersActions.getUsersRequest({} as UserFiltersDTO));
    yield put(UsersActions.getOneUserRequest(user.id));
  } catch (err) {
    const error = handleError(err);
    yield put(UsersActions.updateError(error));
  }
}

export default all([
  takeLatest(UsersTypes.ADD_USER_REQUEST, addUser),
  takeLatest(UsersTypes.GET_USERS_REQUEST, getUsers),
  // takeLatest(UsersTypes.GET_CLIENTS_REQUEST, getClients),
  takeLatest(UsersTypes.GET_ONE_USER_REQUEST, getOneUser),
  takeLatest(UsersTypes.UPDATE_REQUEST, update),
  takeLatest(UsersTypes.CHANGE_ACTIVE_REQUEST, changeActive),
]);
