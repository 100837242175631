import React from 'react';
import { format, parseISO } from 'date-fns';
import { isFirefox } from 'react-device-detect';

import { Icon, IconButton } from '@/components';

import { Container, InputContainer, DateInput } from './styles';

type DateFilterParams = {
  date?: Date;
  setDate: (date: Date | undefined) => void;
  handleSubmitDateFilter: (date: Date | undefined) => void;
};
const DateFilter: React.FC<DateFilterParams> = ({
  date,
  setDate,
  handleSubmitDateFilter,
}) => {
  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
    const parsedDate = target.value ? parseISO(target.value) : undefined;
    setDate(parsedDate);
    handleSubmitDateFilter(parsedDate);
  };

  return (
    <Container>
      <InputContainer>
        <Icon name="calendar" title="filterDate" size={17} />
        <DateInput
          id="filterDate"
          value={date ? format(date, 'yyyy-MM-dd') : ''}
          onChange={onChange}
        />
      </InputContainer>
      {!isFirefox && date && (
        <IconButton onClick={() => setDate(undefined)}>
          <Icon name="x" title="filterDate" size={17} />
        </IconButton>
      )}
    </Container>
  );
};

export default DateFilter;
