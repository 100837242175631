import styled from 'styled-components';

export const LanguageOptions = styled.div`
  display: grid;
  grid-template-columns: 30px max-content;
  align-items: center;
`;

export const Container = styled.div`
  display: grid;
  gap: 4px;
  padding: 2px;
  width: 100%;
`;

type OptionTypes = {
  active: boolean;
};
export const Option = styled.button<OptionTypes>`
  min-height: 1.9rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr;
  column-gap: 15px;
  justify-items: left;
  align-items: center;
  padding: 7px 14px;
  font-family: ${(props) => props.theme.fonts.title};
  border: none;
  border-radius: 100px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary.light : 'transparent'};
  transition: 0.2s background-color;
  > p {
    color: ${({ theme }) => theme.colors.primary.base};
    font-weight: ${({ active }) => (active ? '700' : '400')};
    font-size: 13px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.light};
    > p {
      font-weight: 700;
    }
  }
`;

export const FlagIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 3px;
`;
