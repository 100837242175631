import styled, { css } from 'styled-components';

import { SpanProps } from './types';

const spanTypes = {
  error: css`
    color: ${({ theme }) => theme.colors.error};
  `,
  primary: css`
    color: ${({ theme }) => theme.colors.primary.base};
  `,
};
const Span = styled.span<SpanProps>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 'inherit')};
  font-weight: bold;
  color: ${({ color, theme }) => color || theme.colors.black};
  ${({ type }) => type && spanTypes[type]};
`;

export default Span;
