import { AnyAction, Reducer } from 'redux';
import { createReducer } from 'reduxsauce';
import { action } from 'typesafe-actions';

import {
  AddDistributorFuelDTO,
  DistributorFuelDTO,
  DistributorFuelFilterDTO,
} from '@/dtos/distributorFuels';
import { PageDTO } from '@/dtos/generics';

import { genericError, genericRequest, genericSuccess } from '../utils';
import { DistributorFuelState } from './types';

export enum DistributorFuelsTypes {
  GET_DISTRIBUTOR_FUELS_REQUEST = '@distributorFuels/getDistributorFuelsRequest',
  GET_DISTRIBUTOR_FUELS_SUCCESS = '@distributorFuels/getDistributorFuelsSuccess',
  GET_DISTRIBUTOR_FUELS_ERROR = '@distributorFuels/getDistributorFuelsError',
  GET_DISTRIBUTOR_FUEL_REQUEST = '@distributorFuels/getDistributorFuelRequest',
  GET_DISTRIBUTOR_FUEL_SUCCESS = '@distributorFuels/getDistributorFuelSuccess',
  GET_DISTRIBUTOR_FUEL_ERROR = '@distributorFuels/getDistributorFuelError',
  ADD_DISTRIBUTOR_FUEL_REQUEST = '@distributorFuels/addDistributorFuelRequest',
  ADD_DISTRIBUTOR_FUEL_SUCCESS = '@distributorFuels/addDistributorFuelSuccess',
  ADD_DISTRIBUTOR_FUEL_ERROR = '@distributorFuels/addDistributorFuelError',
  UPDATE_DISTRIBUTOR_FUEL_REQUEST = '@distributorFuels/updateDistributorFuelRequest',
  UPDATE_DISTRIBUTOR_FUEL_SUCCESS = '@distributorFuels/updateDistributorFuelSuccess',
  UPDATE_DISTRIBUTOR_FUEL_ERROR = '@distributorFuels/updateDistributorFuelError',
  SET_FILTERS_DISTRIBUTOR_FUELS = '@distributorFuels/setFiltersDistributorFuels',
  CLEAR_DISTRIBUTOR_FUELS = '@distributorFuels/clearDistributorFuels',
}
const DistributorFuelsActions = {
  getDistributorFuelsRequest: (filters: DistributorFuelFilterDTO) =>
    action(DistributorFuelsTypes.GET_DISTRIBUTOR_FUELS_REQUEST, { filters }),
  getDistributorFuelsSuccess: (data: PageDTO<DistributorFuelDTO>) =>
    action(DistributorFuelsTypes.GET_DISTRIBUTOR_FUELS_SUCCESS, data),
  getDistributorFuelsError: (error: string) =>
    action(DistributorFuelsTypes.GET_DISTRIBUTOR_FUELS_ERROR, { error }),
  getDistributorFuelRequest: (id: string) =>
    action(DistributorFuelsTypes.GET_DISTRIBUTOR_FUEL_REQUEST, { id }),
  getDistributorFuelSuccess: (distributorFuels: DistributorFuelDTO) =>
    action(DistributorFuelsTypes.GET_DISTRIBUTOR_FUEL_SUCCESS, {
      distributorFuels,
    }),
  getDistributorFuelError: (error: string) =>
    action(DistributorFuelsTypes.GET_DISTRIBUTOR_FUEL_ERROR, { error }),
  addDistributorFuelRequest: (data: AddDistributorFuelDTO) =>
    action(DistributorFuelsTypes.ADD_DISTRIBUTOR_FUEL_REQUEST, { data }),
  addDistributorFuelSuccess: () =>
    action(DistributorFuelsTypes.ADD_DISTRIBUTOR_FUEL_SUCCESS),
  addDistributorFuelError: (error: string) =>
    action(DistributorFuelsTypes.ADD_DISTRIBUTOR_FUEL_ERROR, { error }),
  updateDistributorFuelRequest: (data: DistributorFuelDTO) =>
    action(DistributorFuelsTypes.UPDATE_DISTRIBUTOR_FUEL_REQUEST, data),
  updateDistributorFuelSuccess: (distributorFuels: DistributorFuelDTO) =>
    action(DistributorFuelsTypes.UPDATE_DISTRIBUTOR_FUEL_SUCCESS, {
      distributorFuels,
    }),
  updateDistributorFuelError: (error: string) =>
    action(DistributorFuelsTypes.UPDATE_DISTRIBUTOR_FUEL_ERROR, { error }),
  setFiltersDistributorFuels: (filters: DistributorFuelFilterDTO) =>
    action(DistributorFuelsTypes.SET_FILTERS_DISTRIBUTOR_FUELS, { filters }),
  clearDistributorFuels: () =>
    action(DistributorFuelsTypes.CLEAR_DISTRIBUTOR_FUELS),
};
export default DistributorFuelsActions;

const INITIAL_STATE: DistributorFuelState = {
  data: [],
  selected: {} as DistributorFuelDTO,
  currentPage: 1,
  filters: {},
  prev: undefined,
  next: undefined,
  total: 0,
  error: '',
  loading: false,
};

type DistributorFuelReducer<Action extends AnyAction> = Reducer<
  DistributorFuelState,
  Action
>;

const getDistributorFuelsSuccess: DistributorFuelReducer<
  ReturnType<typeof DistributorFuelsActions.getDistributorFuelsSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  prev: payload.prev,
  next: payload.next,
  total: payload.total,
  error: '',
  loading: false,
});

const getDistributorFuelSuccess: DistributorFuelReducer<
  ReturnType<typeof DistributorFuelsActions.getDistributorFuelSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload.distributorFuels,
  error: '',
  loading: false,
});

const clearDistributorFuels: DistributorFuelReducer<any> = () => INITIAL_STATE;

const setFiltersDistributorFuels: DistributorFuelReducer<
  ReturnType<typeof DistributorFuelsActions.setFiltersDistributorFuels>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

export const reducer = createReducer<DistributorFuelState>(INITIAL_STATE, {
  [DistributorFuelsTypes.GET_DISTRIBUTOR_FUELS_REQUEST]: genericRequest,
  [DistributorFuelsTypes.GET_DISTRIBUTOR_FUELS_SUCCESS]:
    getDistributorFuelsSuccess,
  [DistributorFuelsTypes.GET_DISTRIBUTOR_FUELS_ERROR]: genericError,
  [DistributorFuelsTypes.GET_DISTRIBUTOR_FUEL_REQUEST]: genericRequest,
  [DistributorFuelsTypes.GET_DISTRIBUTOR_FUEL_SUCCESS]:
    getDistributorFuelSuccess,
  [DistributorFuelsTypes.GET_DISTRIBUTOR_FUEL_ERROR]: genericError,
  [DistributorFuelsTypes.ADD_DISTRIBUTOR_FUEL_REQUEST]: genericRequest,
  [DistributorFuelsTypes.ADD_DISTRIBUTOR_FUEL_SUCCESS]: genericSuccess,
  [DistributorFuelsTypes.ADD_DISTRIBUTOR_FUEL_ERROR]: genericError,
  [DistributorFuelsTypes.UPDATE_DISTRIBUTOR_FUEL_REQUEST]: genericRequest,
  [DistributorFuelsTypes.UPDATE_DISTRIBUTOR_FUEL_SUCCESS]: genericSuccess,
  [DistributorFuelsTypes.UPDATE_DISTRIBUTOR_FUEL_ERROR]: genericError,
  [DistributorFuelsTypes.CLEAR_DISTRIBUTOR_FUELS]: clearDistributorFuels,
  [DistributorFuelsTypes.SET_FILTERS_DISTRIBUTOR_FUELS]:
    setFiltersDistributorFuels,
});
