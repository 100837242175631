import {
  SubsidiaryDTO,
  AddSubsidiaryDTO,
  SubsidiaryFilterDTO,
} from '@/dtos/subsidiaries';
import { PageDTO } from '@/dtos/generics';
import api from '@/services/api';

class SubsidiaryServices {
  async list(filters: SubsidiaryFilterDTO): Promise<PageDTO<SubsidiaryDTO>> {
    const { data } = await api.get<PageDTO<SubsidiaryDTO>>('/subsidiaries', {
      params: filters,
    });
    return data;
  }
  async find(subsidiaryId: string): Promise<SubsidiaryDTO> {
    const { data } = await api.get<SubsidiaryDTO>(
      `/subsidiaries/${subsidiaryId}`
    );
    return data;
  }
  async create(subsidiary: AddSubsidiaryDTO): Promise<SubsidiaryDTO> {
    const { data } = await api.post<SubsidiaryDTO>('/subsidiaries', subsidiary);
    return data;
  }
  async update(subsidiary: SubsidiaryDTO): Promise<SubsidiaryDTO> {
    const { data } = await api.put<SubsidiaryDTO>(
      `/subsidiaries/${subsidiary.id}`,
      subsidiary
    );
    return data;
  }
}

const subsidiaryServices = new SubsidiaryServices();

export default subsidiaryServices;
