import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ecotracerWithSlogan, metaGlobalTech } from '@/assets/images';
import { ApplicationState } from '@/store/types';
import { SideBarItem } from '@/store/modules/sidebar/types';
import SidebarActions from '@/store/modules/sidebar/duck';

import useCheckPermissionCallback from '@/hooks/useCheckPermissionCallback';
import { useClickOutside } from '@/hooks/useClickOutside';

import { Icon } from '@/components';

import {
  Container,
  Logo,
  Menu,
  MenuLink,
  DropdownButton,
  DropdownMenu,
  DropdownLink,
  CopyrightContainer,
} from './styles';

const checkMenuItems = (
  menuItems: SideBarItem[],
  checkPermission: (permission: string) => boolean,
  userType: string
): SideBarItem[] =>
  menuItems.reduce((accumulator: SideBarItem[], menuItem: SideBarItem) => {
    const notHavePermission =
      menuItem.permission && !checkPermission(menuItem.permission);
    const haveDiffType = !!(menuItem.type && !menuItem.type.includes(userType));
    if (notHavePermission || haveDiffType) return accumulator;
    if (!menuItem.items) return [...accumulator, menuItem];
    if (!checkMenuItems(menuItem.items, checkPermission, userType).length)
      return accumulator;

    return [
      ...accumulator,
      {
        ...menuItem,
        items: checkMenuItems(menuItem.items, checkPermission, userType),
      },
    ];
  }, [] as SideBarItem[]);

const Sidebar: React.FC = () => {
  const menuRef = useRef<HTMLElement>(null);
  const dispatch = useDispatch();
  const checkPermission = useCheckPermissionCallback();
  const userType = useSelector(({ user }: ApplicationState) => user.data.type);
  const menuItems = useSelector(({ sidebar }: ApplicationState) =>
    checkMenuItems(sidebar.items, checkPermission, userType)
  );
  const [dropdownOpen, setDropdownOpen] = useState('');
  const { t: translation } = useTranslation();

  useClickOutside(menuRef, () =>
    dispatch(SidebarActions.setOpenOnMobile(false))
  );

  const sidebarOpenOnMobile = useSelector(
    ({ sidebar }: ApplicationState) => sidebar.openOnMobile
  );

  const handleActiveMenuItem = (item: string, parentItem?: string): void => {
    dispatch(SidebarActions.setMenuItem(item, parentItem));
    dispatch(SidebarActions.setOpenOnMobile(false));
  };

  return (
    <Container open={sidebarOpenOnMobile} ref={menuRef}>
      <Logo src={ecotracerWithSlogan} />
      <Menu>
        {menuItems.map((menuItem) => {
          const iconName = menuItem.icon;
          if (!menuItem.items) {
            return (
              <MenuLink
                key={menuItem.key}
                to={menuItem.to as string}
                active={menuItem.active}
                title={translation(menuItem.label)}
                onClick={() => handleActiveMenuItem(menuItem.key)}
              >
                {iconName && <Icon name={iconName} size={18} />}
                {translation(menuItem.label)}
              </MenuLink>
            );
          }

          const isOpen = dropdownOpen === menuItem.key;
          return (
            <div key={menuItem.key}>
              <DropdownButton
                dropdownOpen={isOpen || menuItem.active}
                active={menuItem.active}
                title={translation(menuItem.label)}
                onClick={() => setDropdownOpen(isOpen ? '' : menuItem.key)}
              >
                {iconName && <Icon name={iconName} size={18} />}
                <p>{translation(menuItem.label)}</p>
                <Icon name="chevron-right" size={18} />
              </DropdownButton>
              <DropdownMenu active={isOpen || menuItem.active}>
                {menuItem.items.map((subItem) => (
                  <li key={subItem.key}>
                    <DropdownLink
                      to={subItem.to as string}
                      onClick={() =>
                        handleActiveMenuItem(subItem.key, menuItem.key)
                      }
                      active={subItem.active}
                    >
                      {translation(subItem.label)}
                    </DropdownLink>
                  </li>
                ))}
              </DropdownMenu>
            </div>
          );
        })}
      </Menu>
      <CopyrightContainer>
        <img src={metaGlobalTech} alt={translation('systemCopyright')} />
      </CopyrightContainer>
    </Container>
  );
};

export default Sidebar;
