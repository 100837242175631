import { combineReducers } from 'redux';

import { reducer as user } from './user/duck';
// import { reducer as company } from './company/duck';
// import { reducer as solicitations } from './solicitations/duck';
import { reducer as users } from './users/duck';
import { reducer as codes } from './codes/duck';
import { reducer as dashboard } from './dashboard/duck';
import { reducer as events } from './events/duck';
import { reducer as roles } from './roles/duck';
import { reducer as permissions } from './permissions/duck';
import { reducer as sidebar } from './sidebar/duck';
import { reducer as invoices } from './invoices/duck';
import { reducer as distributors } from './distributors/duck';
// import { reducer as employees } from './employee/duck';
import { reducer as catalysts } from './catalysts/duck';
import { reducer as fuelTypes } from './fuelTypes/duck';
import { reducer as distributorFuels } from './distributorFuels/duck';
import { reducer as subsidiaries } from './subsidiaries/duck';
// import { reducer as subsidiariesStock } from './subsidiaryStock/duck';
import { reducer as gasStations } from './gasStations/duck';

const reducers = combineReducers({
  user,
  // company,
  // solicitations,
  users,
  codes,
  dashboard,
  events,
  roles,
  permissions,
  sidebar,
  invoices,
  distributors,
  // employees,
  catalysts,
  fuelTypes,
  distributorFuels,
  subsidiaries,
  // subsidiariesStock,
  gasStations,
});

export default reducers;
