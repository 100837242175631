import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

const Login = lazy(() => import('@/pages/Login'));
// const PasswordRecovery = lazy(() => import('@/pages/PasswordRecovery'));
// const PasswordUpdate = lazy(() => import('@/pages/PasswordUpdate'));
// const ActivateAccount = lazy(() => import('@/pages/ActivateAccount'));

const AuthRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route component={() => <Redirect to="/" />} />
    {/* <Route exact path="/recovery" component={PasswordRecovery} />
    <Route path="/recovery/:code" component={PasswordUpdate} />
    <Route path="/activate/:code" component={ActivateAccount} /> */}
  </Switch>
);

export default AuthRoutes;
