import api from '@/services/api';

import { PageDTO } from '@/dtos/generics';
import {
  GasStationDTO,
  GasStationFiltersDTO,
  AddGasStationDTO,
  UpdateGasStationDTO,
} from '@/dtos/gasStations';

class GasStationServices {
  async list(filters: GasStationFiltersDTO): Promise<PageDTO<GasStationDTO>> {
    const { data } = await api.get<PageDTO<GasStationDTO>>('/gas-stations', {
      params: filters,
    });
    return data;
  }
  async find(id: string): Promise<GasStationDTO> {
    const { data } = await api.get<GasStationDTO>(`/gas-stations/${id}`);
    return data;
  }
  async save(gasStationData: AddGasStationDTO): Promise<GasStationDTO> {
    const { data } = await api.post<GasStationDTO>(
      '/gas-stations',
      gasStationData
    );
    return data;
  }
  async update({
    id,
    ...gasStationData
  }: UpdateGasStationDTO): Promise<GasStationDTO> {
    const { data } = await api.put<GasStationDTO>(
      `/gas-stations/${id}`,
      gasStationData
    );
    return data;
  }
}

export default new GasStationServices();
