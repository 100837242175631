import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'reduxsauce';
import { action } from 'typesafe-actions';

import { PageDTO } from '@/dtos/generics';
import DistributorDTO from '@/dtos/distributors/DistributorDTO';
import DistributorFiltersDTO from '@/dtos/distributors/DistributorFiltersDTO';
import AddDistributorDTO from '@/dtos/distributors/AddDistributorDTO';
import UpdateDistributorDTO from '@/dtos/distributors/UpdateDistributorDTO';

import { DistributorState } from './types';
import { genericError, genericRequest, genericSuccess } from '../utils';

export enum DistributorsTypes {
  GET_DISTRIBUTORS_REQUEST = '@distributors/getDistributorsRequest',
  GET_DISTRIBUTORS_SUCCESS = '@distributors/getDistributorsSuccess',
  GET_DISTRIBUTORS_ERROR = '@distributors/getDistributorsError',
  GET_DISTRIBUTOR_REQUEST = '@distributors/getDistributorRequest',
  GET_DISTRIBUTOR_SUCCESS = '@distributors/getDistributorSuccess',
  GET_DISTRIBUTOR_ERROR = '@distributors/getDistributorError',
  ADD_DISTRIBUTOR_REQUEST = '@distributors/addDistributorRequest',
  ADD_DISTRIBUTOR_SUCCESS = '@distributors/addDistributorSuccess',
  ADD_DISTRIBUTOR_ERROR = '@distributors/addDistributorError',
  UPDATE_DISTRIBUTOR_REQUEST = '@distributors/updateDistributorRequest',
  UPDATE_DISTRIBUTOR_SUCCESS = '@distributors/updateDistributorSuccess',
  UPDATE_DISTRIBUTOR_ERROR = '@distributors/updateDistributorError',
  SET_FILTERS_DISTRIBUTOR = '@distributors/setFiltersDistributor',
  CLEAR_DISTRIBUTOR = '@distributors/clearDistributor',
}
const DistributorsActions = {
  getDistributorsRequest: (filters: DistributorFiltersDTO) =>
    action(DistributorsTypes.GET_DISTRIBUTORS_REQUEST, { filters }),
  getDistributorsSuccess: (data: PageDTO<DistributorDTO>) =>
    action(DistributorsTypes.GET_DISTRIBUTORS_SUCCESS, data),
  getDistributorsError: (error: string) =>
    action(DistributorsTypes.GET_DISTRIBUTORS_ERROR, { error }),
  getDistributorRequest: (id: string) =>
    action(DistributorsTypes.GET_DISTRIBUTOR_REQUEST, { id }),
  getDistributorSuccess: (data: DistributorDTO) =>
    action(DistributorsTypes.GET_DISTRIBUTOR_SUCCESS, { data }),
  getDistributorError: (error: string) =>
    action(DistributorsTypes.GET_DISTRIBUTOR_ERROR, { error }),
  addDistributorRequest: (data: AddDistributorDTO) =>
    action(DistributorsTypes.ADD_DISTRIBUTOR_REQUEST, { data }),
  addDistributorSuccess: () =>
    action(DistributorsTypes.ADD_DISTRIBUTOR_SUCCESS),
  addDistributorError: (error: string) =>
    action(DistributorsTypes.ADD_DISTRIBUTOR_ERROR, { error }),
  updateDistributorRequest: (data: UpdateDistributorDTO) =>
    action(DistributorsTypes.UPDATE_DISTRIBUTOR_REQUEST, data),
  updateDistributorSuccess: () =>
    action(DistributorsTypes.UPDATE_DISTRIBUTOR_SUCCESS),
  updateDistributorError: (error: string) =>
    action(DistributorsTypes.UPDATE_DISTRIBUTOR_ERROR, { error }),
  setFiltersDistributor: (filters: DistributorFiltersDTO) =>
    action(DistributorsTypes.SET_FILTERS_DISTRIBUTOR, { filters }),
  clearDistributor: () => action(DistributorsTypes.CLEAR_DISTRIBUTOR),
};
export default DistributorsActions;

const INITIAL_STATE: DistributorState = {
  data: [],
  selected: {
    id: '',
    name: '',
    subsidiaries: [],
    createdAt: new Date(),
    updatedAt: new Date(),
  } as DistributorDTO,
  currentPage: 1,
  filters: {},
  prev: undefined,
  next: undefined,
  total: 0,
  error: '',
  loading: false,
};

type DistributorReducer<Action extends AnyAction> = Reducer<
  DistributorState,
  Action
>;

const getDistributorSuccess: DistributorReducer<
  ReturnType<typeof DistributorsActions.getDistributorSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload.data,
  loading: false,
});

const getDistributorsSuccess: DistributorReducer<
  ReturnType<typeof DistributorsActions.getDistributorsSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  prev: payload.prev,
  next: payload.next,
  total: payload.total,
  error: '',
  loading: false,
});

const clearDistributor: DistributorReducer<
  ReturnType<typeof DistributorsActions.clearDistributor>
> = (state = INITIAL_STATE) => ({ ...state, selected: INITIAL_STATE.selected });

const setFiltersDistributor: DistributorReducer<
  ReturnType<typeof DistributorsActions.setFiltersDistributor>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

export const reducer = createReducer(INITIAL_STATE, {
  [DistributorsTypes.GET_DISTRIBUTORS_REQUEST]: genericRequest,
  [DistributorsTypes.GET_DISTRIBUTORS_SUCCESS]: getDistributorsSuccess,
  [DistributorsTypes.GET_DISTRIBUTORS_ERROR]: genericError,
  [DistributorsTypes.GET_DISTRIBUTOR_REQUEST]: genericRequest,
  [DistributorsTypes.GET_DISTRIBUTOR_SUCCESS]: getDistributorSuccess,
  [DistributorsTypes.GET_DISTRIBUTOR_ERROR]: genericError,
  [DistributorsTypes.ADD_DISTRIBUTOR_REQUEST]: genericRequest,
  [DistributorsTypes.ADD_DISTRIBUTOR_SUCCESS]: genericSuccess,
  [DistributorsTypes.ADD_DISTRIBUTOR_ERROR]: genericError,
  [DistributorsTypes.UPDATE_DISTRIBUTOR_REQUEST]: genericRequest,
  [DistributorsTypes.UPDATE_DISTRIBUTOR_SUCCESS]: genericSuccess,
  [DistributorsTypes.UPDATE_DISTRIBUTOR_ERROR]: genericError,
  [DistributorsTypes.CLEAR_DISTRIBUTOR]: clearDistributor,
  [DistributorsTypes.SET_FILTERS_DISTRIBUTOR]: setFiltersDistributor,
});
