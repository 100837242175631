import { Reducer, AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { createReducer } from 'reduxsauce';

import { SidebarState, SideBarItem } from './types';

export enum SidebarTypes {
  SET_OPEN_ON_MOBILE = '@sidebar/setOpenOnMobile',
  SET_MENU_ITEM = '@sidebar/setMenuItem',
  SET_ITEMS = '@sidebar/setItems',
}
const SidebarActions = {
  setOpenOnMobile: (open: boolean) =>
    action(SidebarTypes.SET_OPEN_ON_MOBILE, { open }),
  setMenuItem: (itemKey: string, parentItemKey?: string) =>
    action(SidebarTypes.SET_MENU_ITEM, { itemKey, parentItemKey }),
};
export default SidebarActions;

const INITIAL_STATE: SidebarState = {
  items: [
    {
      key: 'tracking',
      icon: 'map',
      label: 'sideBar.tracking',
      to: '/tracking',
      permission: 'index:event',
      type: ['company'],
      active: false,
    },
    {
      key: 'event',
      icon: 'layers',
      label: 'sideBar.moviments',
      to: '/events',
      permission: 'index:event',
      type: ['company'],
      active: false,
    },
    {
      key: 'users',
      icon: 'users',
      label: 'sideBar.users',
      to: '/users',
      permission: 'index:user',
      type: ['company', 'distributor'],
      active: false,
      items: [
        {
          key: 'usersList',
          label: 'sideBar.usersList',
          to: '/users',
          permission: 'index:user',
          type: ['company', 'distributor'],
          active: false,
        },
        {
          key: 'usersAdd',
          label: 'sideBar.usersAdd',
          to: '/users/add',
          permission: 'store:user',
          type: ['company', 'distributor'],
          active: false,
        },
      ],
    },
    {
      key: 'distributors',
      icon: 'package',
      label: 'sideBar.distributors',
      active: false,
      permission: 'index:distributor',
      type: ['company'],
      items: [
        {
          key: 'distributorsList',
          label: 'sideBar.distributorsList',
          to: '/distributors',
          permission: 'index:distributor',
          type: ['company'],
          active: false,
        },
        {
          key: 'distributorsAdd',
          label: 'sideBar.distributorsAdd',
          to: '/distributors/add',
          permission: 'store:distributor',
          type: ['company'],
          active: false,
        },
      ],
    },
    {
      key: 'catalysts',
      icon: 'droplet',
      label: 'sideBar.catalysts',
      active: false,
      permission: 'index:catalyst',
      type: ['company'],
      items: [
        {
          key: 'catalystsList',
          label: 'sideBar.catalystsList',
          to: '/catalysts',
          permission: 'index:catalyst',
          type: ['company'],
          active: false,
        },
        {
          key: 'catalystsAdd',
          label: 'sideBar.catalystsAdd',
          to: '/catalysts/add',
          permission: 'store:catalyst',
          type: ['company'],
          active: false,
        },
      ],
    },
    {
      key: 'fuelTypes',
      icon: 'list',
      label: 'sideBar.fuelTypes',
      active: false,
      permission: 'index:fuelType',
      type: ['company'],
      items: [
        {
          key: 'fuelTypesList',
          label: 'sideBar.fuelTypesList',
          to: '/fuel-types',
          permission: 'index:fuelType',
          type: ['company'],
          active: false,
        },
        {
          key: 'fuelTypesAdd',
          label: 'sideBar.fuelTypesAdd',
          to: '/fuel-types/add',
          permission: 'store:fuelType',
          type: ['company'],
          active: false,
        },
      ],
    },
    {
      key: 'distributorFuels',
      icon: 'layers',
      label: 'sideBar.distributorFuels',
      active: false,
      permission: 'index:distributorFuel',
      type: ['distributor'],
      items: [
        {
          key: 'distributorFuelsList',
          label: 'sideBar.distributorFuelsList',
          to: '/distributor-fuels',
          permission: 'index:distributorFuel',
          type: ['distributor'],
          active: false,
        },
        {
          key: 'distributorFuelsAdd',
          label: 'sideBar.distributorFuelsAdd',
          to: '/distributor-fuels/add',
          permission: 'store:distributorFuel',
          type: ['distributor'],
          active: false,
        },
      ],
    },
    {
      key: 'invoices',
      icon: 'file-text',
      label: 'sideBar.invoices',
      to: '/invoices',
      permission: '',
      type: ['company', 'distributor'],
      active: false,
      items: [
        {
          key: 'invoicesList',
          label: 'sideBar.invoicesList',
          to: '/invoices',
          permission: 'index:invoice',
          type: ['company', 'distributor'],
          active: false,
        },
        {
          key: 'invoicesAdd',
          label: 'sideBar.invoicesAdd',
          to: '/invoices/import',
          permission: 'store:invoice',
          type: ['distributor'],
          active: false,
        },
      ],
    },
    {
      key: 'gasStations',
      icon: 'filter',
      label: 'sideBar.gasStations',
      to: '/gas-stations',
      permission: 'index:gasStation',
      type: ['distributor'],
      active: false,
    },
    // {
    //   key: 'company',
    //   icon: FiBriefcase,
    //   label: 'sideBar.company',
    //   to: '/company',
    //   permission: 'show:company',
    //   type: ['company'],
    //   active: false,
    // },
    {
      key: 'security',
      icon: 'shield',
      label: 'sideBar.security',
      to: '/security',
      permission: 'store:role',
      type: ['distributor', 'company'],
      active: false,
    },
    {
      key: 'settings',
      icon: 'settings',
      label: 'sideBar.settings',
      to: '/settings',
      permission: '',
      type: ['company'],
      active: false,
    },
  ],
  openOnMobile: false,
  loading: false,
  error: '',
};

export function checkMenuItems(
  items: SideBarItem[],
  checkPermission: (permission: string) => boolean,
  userType: string
): SideBarItem[] {
  return items.reduce((accumulator: SideBarItem[], menuItem: SideBarItem) => {
    const notHavePermission =
      menuItem.permission && !checkPermission(menuItem.permission);
    const haveDiffType = !!(menuItem.type && !menuItem.type.includes(userType));
    if (notHavePermission || haveDiffType) return accumulator;
    if (!menuItem.items) return [...accumulator, menuItem];
    if (!checkMenuItems(menuItem.items, checkPermission, userType).length)
      return accumulator;

    return [
      ...accumulator,
      {
        ...menuItem,
        items: checkMenuItems(menuItem.items, checkPermission, userType),
      },
    ];
  }, [] as SideBarItem[]);
}

type SidebarReducer<Action extends AnyAction> = Reducer<SidebarState, Action>;

const setMenuItem: SidebarReducer<
  ReturnType<typeof SidebarActions.setMenuItem>
> = (state = INITIAL_STATE, { payload }) => {
  const key = payload.parentItemKey || payload.itemKey;
  return {
    ...state,
    items: state.items.map((item) => ({
      ...item,
      active: key === item.key,
      items: item?.items?.map((subItem) => ({
        ...subItem,
        active: subItem.key === payload.itemKey,
      })),
    })),
  };
};

const setOpenOnMobile: SidebarReducer<
  ReturnType<typeof SidebarActions.setOpenOnMobile>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  openOnMobile: payload.open,
  loading: false,
  error: '',
});

export const reducer = createReducer(INITIAL_STATE, {
  [SidebarTypes.SET_OPEN_ON_MOBILE]: setOpenOnMobile,
  [SidebarTypes.SET_MENU_ITEM]: setMenuItem,
});
