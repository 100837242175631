import styled, { keyframes } from 'styled-components';

import { IconButton } from '@/components';
import { ContainerProps, ModalContentProps } from './types';

const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fade = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const Container = styled.div<ContainerProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 101;
  animation: ${fade} 0.2s ease-in;
`;

export const Content = styled.div`
  min-width: 35vw;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  gap: 1.2rem;
  padding: 1.5rem 1.1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  outline: 0;
  box-shadow: ${({ theme }) =>
    `${theme.sizes.shadow} ${theme.colors.gray.dark}`};
  animation: ${slideUp} 0.2s cubic-bezier(0.45, 0.34, 0.4, 1.26);
`;

export const TitleContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-template-columns: 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin-bottom: 1.8rem;
  > button {
    justify-self: end;
    align-self: start;
  }
`;

export const CloseIcon = styled(IconButton)`
  align-self: start;
`;

export const ModalContent = styled.div<ModalContentProps>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-auto-rows: 1fr auto;
  gap: ${(props) => props.gap || '1rem'};
`;
