import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, min-content);
  column-gap: 0.2rem;
`;

export const InputContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    div > svg,
    input {
      color: ${({ theme }) => theme.colors.primary.base};
    }
  }
`;

export const DateInput = styled.input.attrs({
  type: 'date',
})`
  width: min-content;
  border: none;
  background-color: transparent;
  position: relative;
  transition: color 0.3s;
  margin-left: 0.2rem;
  &::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background-image: none;
    cursor: pointer;
  }
`;
