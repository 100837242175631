import styled, { keyframes } from 'styled-components';

import Row from '@/components/Layout/Row';

const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 1rem;
  list-style: none;
`;

export const ListItem = styled.li`
  background-color: #ffffff;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  animation: ${slideUp} 0.2s ease-in-out;

  overflow: hidden;
`;

type ListItemContentProps = {
  selected?: boolean;
};
export const ListItemContent = styled(Row)<ListItemContentProps>`
  align-items: center;
  padding: 0.4rem 0.8rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary.base : theme.colors.border};
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  transition: border-color 0.2s ease-in;
`;

export const ListItemFooter = styled(Row)`
  padding: 0.4rem 0.8rem;
  background-color: ${(props) => props.theme.colors.primary.base};
  p {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }
`;
