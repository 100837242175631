import styled from 'styled-components';

import { mapLoading } from '@/assets/images';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  background: url(${mapLoading}) center center no-repeat;
  background-size: 150px 150px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
`;

export default LoadingContainer;
