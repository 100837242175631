import React, { useState, useRef } from 'react';

import { useClickOutside } from '@/hooks/useClickOutside';

import { NavbarParams, TitleDTO } from './types';
import Layout from './Layout';

const Navbar: React.FC<NavbarParams> = ({
  title,
  isSearchOpen,
  setSearchOpen,
  goBack,
}) => {
  const [isLangMenuOpen, setLangMenuOpen] = useState(false);
  const [isSettingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const languageMenuRef = useRef(null);
  const settingsMenuRef = useRef(null);

  useClickOutside(languageMenuRef, () => setLangMenuOpen(false));

  useClickOutside(settingsMenuRef, () => setSettingsMenuOpen(false));

  const parseTitle = ([main, ...breadcrumb]: string[]): TitleDTO => ({
    main,
    breadcrumb: breadcrumb.length ? ` / ${breadcrumb.join(' / ')}` : undefined,
  });

  return (
    <Layout
      title={parseTitle(title)}
      isSearchOpen={isSearchOpen}
      setSearchOpen={setSearchOpen}
      setLangMenuOpen={setLangMenuOpen}
      isLangMenuOpen={isLangMenuOpen}
      setSettingsMenuOpen={setSettingsMenuOpen}
      isSettingsMenuOpen={isSettingsMenuOpen}
      languageMenuRef={languageMenuRef}
      settingsMenuRef={settingsMenuRef}
      goBack={goBack}
    />
  );
};

export default Navbar;
