import api from '@/services/api';
import { Position } from '@/services/position';

import {
  InvoiceFilterDTO,
  InvoiceListResultDTO,
  AddInvoiceDTO,
  InvoiceDTO,
} from '@/dtos/invoices';

class InvoiceServices {
  async list(params: InvoiceFilterDTO): Promise<InvoiceListResultDTO> {
    const { data } = await api.get<InvoiceListResultDTO>('/invoices', {
      params,
    });
    return data;
  }
  async find(invoiceId: string): Promise<InvoiceDTO> {
    const { data } = await api.get<InvoiceDTO>(`/invoices/${invoiceId}`);
    return data;
  }
  async findByAccessKey(invoiceId: string): Promise<InvoiceDTO> {
    const { data } = await api.get<InvoiceDTO>(`/invoices/${invoiceId}`);
    return data;
  }
  async create({
    files,
    latitude,
    longitude,
    distributorId,
  }: AddInvoiceDTO): Promise<void> {
    await api.post('/invoices/import', files, {
      params: {
        latitude,
        longitude,
        distributorId,
      },
    });
  }
  async cancelInvoice(invoiceId: string, data: Position): Promise<void> {
    await api.delete<void>(`/invoices/${invoiceId}`, {
      params: data,
    });
  }
}

const invoiceServices = new InvoiceServices();

export default invoiceServices;
