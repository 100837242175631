import React, { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ApplicationState } from '@/store/types';
import { checkMenuItems } from '@/store/modules/sidebar/duck';
import Sidebar from '@/components/Layout/Sidebar';
import Loader from '@/components/Loader';
import Route from '@/routes/Route';
import useCheckPermissionCallback from '@/hooks/useCheckPermissionCallback';
import { Container, Content, Main } from './styles';

// event
const Tracking = lazy(() => import('@/pages/Tracking'));
const AddEvent = lazy(() => import('@/pages/AddEvent'));
// users
const UsersList = lazy(() => import('@/pages/Users/List'));
const UsersSave = lazy(() => import('@/pages/Users/Save'));
const UsersEdit = lazy(() => import('@/pages/Users/Edit'));
// distributors
const DistributorsList = lazy(() => import('@/pages/Distributors/List'));
const DistributorSave = lazy(() => import('@/pages/Distributors/Save'));
const DistributorEdit = lazy(() => import('@/pages/Distributors/Edit'));
const DistributorDetails = lazy(() => import('@/pages/Distributors/Details'));
// catalsts
const CatalystList = lazy(() => import('@/pages/Catalysts/List'));
const CatalystSave = lazy(() => import('@/pages/Catalysts/Save'));
const CatalystEdit = lazy(() => import('@/pages/Catalysts/Edit'));
// fuel types
const FuelTypesList = lazy(() => import('@/pages/FuelTypes/List'));
const FuelTypesSave = lazy(() => import('@/pages/FuelTypes/Save'));
const FuelTypesEdit = lazy(() => import('@/pages/FuelTypes/Edit'));
// distributor fuels
const DistributorFuelList = lazy(() => import('@/pages/DistributorFuels/List'));
const DistributorFuelSave = lazy(() => import('@/pages/DistributorFuels/Save'));
const DistributorFuelEdit = lazy(() => import('@/pages/DistributorFuels/Edit'));
// roles
const SafetySave = lazy(() => import('@/pages/Safety/Save'));
const SafetyEdit = lazy(() => import('@/pages/Safety/Edit'));
// invoices
const InvoiceList = lazy(() => import('@/pages/Invoices/List'));
const InvoiceSave = lazy(() => import('@/pages/Invoices/Save'));
const InvoiceDetails = lazy(() => import('@/pages/Invoices/Details'));
// gas stations
const GasStationsList = lazy(() => import('@/pages/GasStations/List'));
// settings
const Settings = lazy(() => import('@/pages/Settings'));

const AppRoute: React.FC = () => {
  const { t: translation } = useTranslation();
  const checkPermission = useCheckPermissionCallback();
  const userType = useSelector(({ user }: ApplicationState) => user.data.type);
  const items = useSelector((store: ApplicationState) =>
    checkMenuItems(store.sidebar.items, checkPermission, userType)
  );
  const firstPageWithPermission = (): string => {
    const firstPage = items[0];
    const firstPageSubItem = firstPage?.items && firstPage?.items[0];
    return firstPage?.to || firstPageSubItem?.to || '';
  };

  return (
    <Container>
      <Sidebar />
      <Main>
        <Content id="main-content">
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to={firstPageWithPermission()} />}
              />
              <Route
                exact
                type={['company']}
                path="/tracking"
                permission="index:event"
                component={Tracking}
              />
              <Route
                exact
                type={['company']}
                path="/events"
                permission="index:event"
                component={AddEvent}
              />
              <Route
                exact
                type={['company', 'distributor']}
                path="/users"
                permission="index:user"
                component={UsersList}
              />
              <Route
                exact
                type={['company', 'distributor']}
                path="/users/add"
                permission="store:user"
                component={UsersSave}
              />
              <Route
                exact
                type={['company', 'distributor']}
                path="/users/:id/edit"
                permission="update:user"
                component={UsersEdit}
              />
              <Route
                exact
                type={['company']}
                path="/distributors"
                permission="index:distributor"
                component={DistributorsList}
              />
              <Route
                exact
                type={['company']}
                path="/distributors/add"
                permission="store:distributor"
                component={DistributorSave}
              />
              <Route
                type={['company']}
                path="/distributors/edit/:id"
                permission="update:distributor"
                component={DistributorEdit}
              />
              <Route
                exact
                type={['company']}
                path="/distributors/:id/details"
                permission="show:distributor"
                component={DistributorDetails}
              />
              <Route
                exact
                type={['company']}
                path="/catalysts"
                permission="index:catalyst"
                component={CatalystList}
              />
              <Route
                exact
                type={['company']}
                path="/catalysts/add"
                permission="store:catalyst"
                component={CatalystSave}
              />
              <Route
                exact
                type={['company']}
                path="/catalysts/edit/:id"
                permission="update:catalyst"
                component={CatalystEdit}
              />
              <Route
                exact
                type={['company']}
                path="/fuel-types"
                permission="index:fuelType"
                component={FuelTypesList}
              />
              <Route
                exact
                type={['company']}
                path="/fuel-types/add"
                permission="store:fuelType"
                component={FuelTypesSave}
              />
              <Route
                exact
                type={['company']}
                path="/fuel-types/edit/:id"
                permission="update:fuelType"
                component={FuelTypesEdit}
              />
              <Route
                exact
                type={['distributor']}
                path="/distributor-fuels"
                permission="index:distributorFuel"
                component={DistributorFuelList}
              />
              <Route
                exact
                type={['distributor']}
                path="/distributor-fuels/add"
                permission="store:distributorFuel"
                component={DistributorFuelSave}
              />
              <Route
                exact
                type={['distributor']}
                path="/distributor-fuels/edit/:id"
                permission="update:distributorFuel"
                component={DistributorFuelEdit}
              />
              <Route
                exact
                type={['company', 'distributor']}
                path="/invoices"
                permission="index:invoice"
                component={InvoiceList}
              />
              <Route
                exact
                type={['company', 'distributor']}
                path="/invoices/:id/details"
                permission="show:invoice"
                component={InvoiceDetails}
              />
              <Route
                exact
                type={['distributor']}
                path="/invoices/import"
                permission="store:invoice"
                component={InvoiceSave}
              />
              <Route
                type={['company']}
                path="/invoices/import/:id"
                permission="store:invoice"
                component={InvoiceSave}
              />
              <Route
                exact
                type={['distributor']}
                path="/gas-stations"
                permission="index:gasStation"
                component={GasStationsList}
              />
              <Route
                exact
                type={['company', 'distributor']}
                path="/security"
                permission="index:role"
                component={SafetySave}
              />
              <Route
                type={['company', 'distributor']}
                path="/security/role/edit/:id"
                permission="update:role"
                component={SafetyEdit}
              />
              <Route
                exact
                type={['company', 'distributor']}
                path="/settings"
                component={Settings}
              />
              {/* <Route
                exact
                path="/gas-stations/details/:id"
                permission="index:gasStation"
                component={GasStationsDetails}
              />
              <Route
                exact
                path="/invoices/details/:id"
                permission="show:invoice"
                component={InvoiceDetails}
              />
              <Route
                exact
                path="/invoices/details/:id/products"
                permission="show:invoice"
                component={InvoiceProduct}
              />
              <Route
                path="/company"
                permission="show:company"
                component={Company}
              /> */}
              <Route
                component={() => {
                  toast.error(translation('error.validation.page_not_found'));

                  return <Redirect to={firstPageWithPermission()} />;
                }}
              />
            </Switch>
          </Suspense>
        </Content>
      </Main>
    </Container>
  );
};

export default AppRoute;
