import AddDistributorDTO from '@/dtos/distributors/AddDistributorDTO';
import DistributorDTO from '@/dtos/distributors/DistributorDTO';
import DistributorFiltersDTO from '@/dtos/distributors/DistributorFiltersDTO';
import UpdateDistributorDTO from '@/dtos/distributors/UpdateDistributorDTO';
import { PageDTO } from '@/dtos/generics';
import api from '@/services/api';

class DistributorServices {
  async list(filters: DistributorFiltersDTO): Promise<PageDTO<DistributorDTO>> {
    const { data } = await api.get<PageDTO<DistributorDTO>>('/distributors', {
      params: filters,
    });
    return data;
  }
  async find(distributorId: string): Promise<DistributorDTO> {
    const { data } = await api.get<DistributorDTO>(
      `/distributors/${distributorId}`
    );
    return data;
  }
  async create(distributor: AddDistributorDTO): Promise<DistributorDTO> {
    const { data } = await api.post<DistributorDTO>(
      '/distributors',
      distributor
    );
    return data;
  }
  async update(distributor: UpdateDistributorDTO): Promise<DistributorDTO> {
    const { data } = await api.put<DistributorDTO>(
      `/distributors/${distributor.id}`,
      distributor
    );
    return data;
  }
}

const distributorServices = new DistributorServices();

export default distributorServices;
