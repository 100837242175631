import styled, { keyframes } from 'styled-components';

const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

type MenuProps = {
  show: boolean;
};
export const Menu = styled.div<MenuProps>`
  position: absolute;
  top: ${({ theme }) => theme.sizes.header};
  right: 0;
  display: ${({ show }) => (show ? 'grid' : 'none')};
  width: max-content;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  row-gap: 10px;
  margin-top: 6px;
  padding: 15px 12px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: ${({ theme }) => `${theme.sizes.shadow} ${theme.colors.shadow}`};
  animation: ${slideUp} 0.1s ease-in-out;
  z-index: 999;
`;

export const Option = styled.button`
  min-height: 1.9rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr;
  column-gap: 15px;
  justify-items: left;
  align-items: center;
  padding: 7px 14px;
  font-family: ${(props) => props.theme.fonts.title};
  border: none;
  border-radius: 100px;
  background-color: transparent;
  transition: 0.2s background-color;
  > p {
    color: ${({ theme }) => theme.colors.primary.base};
    font-weight: 400;
    font-size: 13px;
    font-family: inherit;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.light};
    > p {
      font-weight: 700;
    }
  }
`;
