import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import handleError from '@/adapters/httpErrors';
import i18next from '@/locale';
import history from '@/services/history';
import distributorFuelServices from '@/lib/distributorFuels';
import { DistributorFuelDTO } from '@/dtos/distributorFuels';
import { PageDTO } from '@/dtos/generics';
import DistributorFuelsActions, { DistributorFuelsTypes } from './duck';

const distributorFuelsAdd = i18next.t('Success.distributorFuelsAdd');
const distributorFuelsUpdate = i18next.t('Success.distributorFuelsUpdate');

type GetDistributorFuelsParams = ReturnType<
  typeof DistributorFuelsActions.getDistributorFuelsRequest
>;
export function* getDistributorFuels({
  payload,
}: GetDistributorFuelsParams): Generator {
  try {
    const data = yield call(distributorFuelServices.list, payload.filters);
    yield put(
      DistributorFuelsActions.getDistributorFuelsSuccess(
        data as PageDTO<DistributorFuelDTO>
      )
    );
  } catch (err) {
    const error = handleError(err);
    yield put(DistributorFuelsActions.getDistributorFuelsError(error));
  }
}

type GetDistributorFuelParams = ReturnType<
  typeof DistributorFuelsActions.getDistributorFuelRequest
>;
export function* getDistributorFuel({
  payload,
}: GetDistributorFuelParams): Generator {
  try {
    const data = yield call(distributorFuelServices.find, payload.id);
    yield put(
      DistributorFuelsActions.getDistributorFuelSuccess(
        data as DistributorFuelDTO
      )
    );
  } catch (err) {
    const error = handleError(err);
    yield put(DistributorFuelsActions.getDistributorFuelError(error));
  }
}

type AddDistributorFuelParams = ReturnType<
  typeof DistributorFuelsActions.addDistributorFuelRequest
>;
export function* addDistributorFuel({
  payload,
}: AddDistributorFuelParams): Generator {
  try {
    yield call(distributorFuelServices.create, payload.data);
    yield put(DistributorFuelsActions.addDistributorFuelSuccess());
    toast.success(distributorFuelsAdd);
    history.push('/distributor-fuels');
  } catch (err) {
    const error = handleError(err);
    yield put(DistributorFuelsActions.addDistributorFuelError(error));
  }
}

type UpdateDistributorFuelParams = ReturnType<
  typeof DistributorFuelsActions.updateDistributorFuelRequest
>;
export function* updateDistributorFuel({
  payload,
}: UpdateDistributorFuelParams): Generator {
  try {
    const data = yield call(distributorFuelServices.update, payload);
    yield put(
      DistributorFuelsActions.updateDistributorFuelSuccess(
        data as DistributorFuelDTO
      )
    );
    yield put(DistributorFuelsActions.getDistributorFuelsRequest({}));
    yield put(DistributorFuelsActions.getDistributorFuelRequest(payload.id));
    toast.success(distributorFuelsUpdate);
    history.push('/distributor-fuels');
  } catch (err) {
    const error = handleError(err);
    yield put(DistributorFuelsActions.updateDistributorFuelError(error));
  }
}

export default all([
  takeLatest(
    DistributorFuelsTypes.GET_DISTRIBUTOR_FUELS_REQUEST,
    getDistributorFuels
  ),
  takeLatest(
    DistributorFuelsTypes.GET_DISTRIBUTOR_FUEL_REQUEST,
    getDistributorFuel
  ),
  takeLatest(
    DistributorFuelsTypes.ADD_DISTRIBUTOR_FUEL_REQUEST,
    addDistributorFuel
  ),
  takeLatest(
    DistributorFuelsTypes.UPDATE_DISTRIBUTOR_FUEL_REQUEST,
    updateDistributorFuel
  ),
]);
