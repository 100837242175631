import React, { memo, useCallback } from 'react';

import { Icon } from '@/components';
import { OrderByProps } from './types';

const OrderBy: React.FC<OrderByProps> = ({
  order,
  orderByAsc,
  orderByDesc,
  clear,
}) => {
  const handleOrder = useCallback(
    (isUp: string): (() => void) => {
      if (isUp === order) return clear;
      if (isUp === 'ASC') return orderByAsc;

      return orderByDesc;
    },
    [order, clear, orderByDesc, orderByAsc]
  );
  const handleUp = handleOrder('DESC');
  const handleDown = handleOrder('ASC');

  return (
    <div>
      <div onClick={handleUp}>
        <Icon
          name="arrow-up"
          size={18}
          color="#aaa"
          active={order === 'DESC'}
        />
      </div>
      <div onClick={handleDown}>
        <Icon
          name="arrow-down"
          size={18}
          color="#aaa"
          active={order === 'ASC'}
        />
      </div>
    </div>
  );
};

export default memo(OrderBy);
