import React, { forwardRef, InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Container, Field } from './styles';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  id?: string;
  error?: boolean;
  toClipboard?: boolean;
}
const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { label, id: identify, error, ...rest },
  ref
) => {
  const id = identify || uuid();
  const { t: translation } = useTranslation();

  return (
    <Container htmlFor={id}>
      <Field
        id={id}
        {...rest}
        ref={ref}
        error={error}
        placeholder={translation(`input.${label}`)}
      />
    </Container>
  );
};

export default forwardRef(Input);
