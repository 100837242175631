import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';

import history from '@/services/history';

import handleError from '@/adapters/httpErrors';

import distributorServices from '@/lib/distributors';

import { PageDTO } from '@/dtos/generics';
import DistributorDTO from '@/dtos/distributors/DistributorDTO';

import DistributorsActions, { DistributorsTypes } from './duck';

type GetDistributorsParams = ReturnType<
  typeof DistributorsActions.getDistributorsRequest
>;
export function* getDistributors({
  payload,
}: GetDistributorsParams): Generator {
  try {
    const data = yield call(distributorServices.list, payload.filters);
    yield put(
      DistributorsActions.getDistributorsSuccess(
        data as PageDTO<DistributorDTO>
      )
    );
  } catch (err) {
    const error = handleError(err);
    yield put(DistributorsActions.getDistributorsError(error));
  }
}

type GetDistributorParams = ReturnType<
  typeof DistributorsActions.getDistributorRequest
>;
export function* getDistributor({ payload }: GetDistributorParams): Generator {
  try {
    const data = yield call(distributorServices.find, payload.id);
    yield put(
      DistributorsActions.getDistributorSuccess(data as DistributorDTO)
    );
  } catch (err) {
    const error = handleError(err);
    yield put(DistributorsActions.getDistributorError(error));
  }
}

type AddDistributorParams = ReturnType<
  typeof DistributorsActions.addDistributorRequest
>;
export function* addDistributor({ payload }: AddDistributorParams): Generator {
  try {
    yield call(distributorServices.create, payload.data);
    yield put(DistributorsActions.addDistributorSuccess());
    history.push('/distributors');
    toast.success(i18next.t('success.distributorRegistered'));
  } catch (err) {
    const error = handleError(err);
    yield put(DistributorsActions.addDistributorError(error));
  }
}

type UpdateDistributorParams = ReturnType<
  typeof DistributorsActions.updateDistributorRequest
>;
export function* updateDistributor({
  payload,
}: UpdateDistributorParams): Generator {
  try {
    yield call(distributorServices.update, payload);
    yield put(DistributorsActions.updateDistributorSuccess());
    yield put(DistributorsActions.getDistributorsRequest({}));
    yield put(DistributorsActions.getDistributorRequest(payload.id));
    toast.success(i18next.t('success.updatedData'));
    history.push('/distributors');
  } catch (err) {
    const error = handleError(err);
    yield put(DistributorsActions.updateDistributorError(error));
  }
}

export default all([
  takeLatest(DistributorsTypes.GET_DISTRIBUTORS_REQUEST, getDistributors),
  takeLatest(DistributorsTypes.GET_DISTRIBUTOR_REQUEST, getDistributor),
  takeLatest(DistributorsTypes.UPDATE_DISTRIBUTOR_REQUEST, updateDistributor),
  takeLatest(DistributorsTypes.ADD_DISTRIBUTOR_REQUEST, addDistributor),
]);
