import api from '@/services/api';

import { IncreaseSubsidiaryFuelStocksDTO } from '@/dtos/subsidiaryFuelStocks';

class SubsidiaryFuelStocks {
  static async increaseStock(
    data: IncreaseSubsidiaryFuelStocksDTO
  ): Promise<void> {
    await api.post<void>('/fuel-stocks/input', data);
  }
}

export default SubsidiaryFuelStocks;
