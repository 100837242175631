import { all } from 'redux-saga/effects';

import user from './user/sagas';
// import company from './company/sagas';
import users from './users/sagas';
// import solicitations from './solicitations/sagas';
import codes from './codes/sagas';
import roles from './roles/sagas';
import permissions from './permissions/sagas';
import events from './events/sagas';
import invoices from './invoices/sagas';
import distributors from './distributors/sagas';
// import employees from './employee/sagas';
import catalysts from './catalysts/sagas';
import fuelTypes from './fuelTypes/sagas';
import distributorFuels from './distributorFuels/sagas';
import subsidiaries from './subsidiaries/sagas';
// import subsidiariesStock from './subsidiaryStock/sagas';
import gasStations from './gasStations/sagas';

export default function* rootSaga(): Generator {
  yield all([
    user,
    // company,
    users,
    // solicitations,
    codes,
    roles,
    permissions,
    events,
    invoices,
    distributors,
    // employees,
    catalysts,
    fuelTypes,
    distributorFuels,
    subsidiaries,
    // subsidiariesStock,
    gasStations,
  ]);
}
