import {
  FuelTypeDTO,
  AddFuelTypeDTO,
  FuelTypeFilterDTO,
} from '@/dtos/fuelTypes';
import { PageDTO } from '@/dtos/generics';
import api from '@/services/api';

class FuelTypeServices {
  async list(filters: FuelTypeFilterDTO): Promise<PageDTO<FuelTypeDTO>> {
    const { data } = await api.get<PageDTO<FuelTypeDTO>>('/fuelTypes', {
      params: filters,
    });
    return data;
  }
  async find(fuelTypeId: string): Promise<FuelTypeDTO> {
    const { data } = await api.get<FuelTypeDTO>(`/fuelTypes/${fuelTypeId}`);
    return data;
  }
  async create(fuelType: AddFuelTypeDTO): Promise<FuelTypeDTO> {
    const { data } = await api.post<FuelTypeDTO>('/fuelTypes', fuelType);
    return data;
  }
  async update(fuelType: FuelTypeDTO): Promise<FuelTypeDTO> {
    const { data } = await api.put<FuelTypeDTO>(
      `/fuelTypes/${fuelType.id}`,
      fuelType
    );
    return data;
  }
}

const fuelTypeServices = new FuelTypeServices();

export default fuelTypeServices;
