import { all, takeLatest, call, put } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import eventServices from '@/lib/events';
import codeServices from '@/lib/codes';
import EventDTO from '@/dtos/events/EventDTO';

import CodesActions, { CodesTypes } from './duck';

type GetEventsParams = ReturnType<typeof CodesActions.getEventsRequest>;
export function* getEvents({ payload }: GetEventsParams): Generator {
  try {
    const { code, filters } = payload;
    const data = yield call(eventServices.findByCode, code, filters);
    yield put(CodesActions.getEventsSuccess(data as EventDTO[]));
  } catch (err) {
    const error = handleError(err);
    yield put(CodesActions.getEventsError(error));
  }
}

type GetCodesBySolicitation = ReturnType<
  typeof CodesActions.getCodesBySolicitationRequest
>;
export function* getCodesBySolicitation({
  payload,
}: GetCodesBySolicitation): Generator {
  try {
    const data = yield call(
      codeServices.findBySolicitation,
      payload.solicitationId
    );
    yield put(CodesActions.getCodesBySolicitationSuccess(data as string[]));
  } catch (err) {
    const error = handleError(err);
    yield put(CodesActions.getCodesBySolicitationError(error));
  }
}

export default all([
  takeLatest(CodesTypes.GET_EVENTS_REQUEST, getEvents),
  takeLatest(
    CodesTypes.GET_CODES_BY_SOLICITATION_REQUEST,
    getCodesBySolicitation
  ),
]);
