import { createReducer } from 'reduxsauce';
import { action } from 'typesafe-actions';
import { Reducer, AnyAction } from 'redux';

import {
  AddFuelTypeDTO,
  FuelTypeDTO,
  FuelTypeFilterDTO,
} from '@/dtos/fuelTypes';
import { PageDTO } from '@/dtos/generics';

import { FuelTypesState } from './types';
import { genericError, genericRequest, genericSuccess } from '../utils';

export enum FuelTypesTypes {
  GET_FUEL_TYPES_REQUEST = '@fuelTypes/getFuelTypesRequest',
  GET_FUEL_TYPES_SUCCESS = '@fuelTypes/getFuelTypesSuccess',
  GET_FUEL_TYPES_ERROR = '@fuelTypes/getFuelTypesError',
  GET_FUEL_TYPE_REQUEST = '@fuelTypes/getFuelTypeRequest',
  GET_FUEL_TYPE_SUCCESS = '@fuelTypes/getFuelTypeSuccess',
  GET_FUEL_TYPE_ERROR = '@fuelTypes/getFuelTypeError',
  ADD_FUEL_TYPE_REQUEST = '@fuelTypes/addFuelTypeRequest',
  ADD_FUEL_TYPE_SUCCESS = '@fuelTypes/addFuelTypeSuccess',
  ADD_FUEL_TYPE_ERROR = '@fuelTypes/addFuelTypeError',
  UPDATE_FUEL_TYPE_REQUEST = '@fuelTypes/updateFuelTypeRequest',
  UPDATE_FUEL_TYPE_SUCCESS = '@fuelTypes/updateFuelTypeSuccess',
  UPDATE_FUEL_TYPE_ERROR = '@fuelTypes/updateFuelTypeError',
  SET_FILTERS_FUEL_TYPES = '@fuelTypes/setFiltersFuelTypes',
  CLEAR_SELECTED = '@fuelTypes/clearSelected',
  CLEAR_FUEL_TYPES = '@fuelTypes/clearFuelTypes',
}
const FuelTypesActions = {
  getFuelTypesRequest: (filters: FuelTypeFilterDTO) =>
    action(FuelTypesTypes.GET_FUEL_TYPES_REQUEST, { filters }),
  getFuelTypesSuccess: (data: PageDTO<FuelTypeDTO>) =>
    action(FuelTypesTypes.GET_FUEL_TYPES_SUCCESS, data),
  getFuelTypesError: (error: string) =>
    action(FuelTypesTypes.GET_FUEL_TYPES_ERROR, { error }),
  getFuelTypeRequest: (id: string) =>
    action(FuelTypesTypes.GET_FUEL_TYPE_REQUEST, { id }),
  getFuelTypeSuccess: (fuelTypes: FuelTypeDTO) =>
    action(FuelTypesTypes.GET_FUEL_TYPE_SUCCESS, { fuelTypes }),
  getFuelTypeError: (error: string) =>
    action(FuelTypesTypes.GET_FUEL_TYPE_ERROR, { error }),
  addFuelTypeRequest: (data: AddFuelTypeDTO) =>
    action(FuelTypesTypes.ADD_FUEL_TYPE_REQUEST, { data }),
  addFuelTypeSuccess: () => action(FuelTypesTypes.ADD_FUEL_TYPE_SUCCESS),
  addFuelTypeError: (error: string) =>
    action(FuelTypesTypes.ADD_FUEL_TYPE_ERROR, { error }),
  updateFuelTypeRequest: (data: FuelTypeDTO) =>
    action(FuelTypesTypes.UPDATE_FUEL_TYPE_REQUEST, { data }),
  updateFuelTypeSuccess: (fuelTypes: FuelTypeDTO) =>
    action(FuelTypesTypes.UPDATE_FUEL_TYPE_SUCCESS, { fuelTypes }),
  updateFuelTypeError: (error: string) =>
    action(FuelTypesTypes.UPDATE_FUEL_TYPE_ERROR, { error }),
  setFiltersFuelTypes: (filters: FuelTypeFilterDTO) =>
    action(FuelTypesTypes.SET_FILTERS_FUEL_TYPES, { filters }),
  clearSelected: () => action(FuelTypesTypes.CLEAR_SELECTED),
  clearFuelTypes: () => action(FuelTypesTypes.CLEAR_FUEL_TYPES),
};
export default FuelTypesActions;

const INITIAL_STATE: FuelTypesState = {
  data: [],
  selectedFuelType: {} as FuelTypeDTO,
  currentPage: 1,
  filters: {},
  prev: undefined,
  next: undefined,
  total: 0,
  error: '',
  loading: false,
};

export type CodesReducer<Action extends AnyAction> = Reducer<
  FuelTypesState,
  Action
>;

const getFuelTypesSuccess: CodesReducer<
  ReturnType<typeof FuelTypesActions.getFuelTypesSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  prev: payload.prev,
  next: payload.next,
  total: payload.total,
  error: '',
  loading: false,
});

const getFuelTypeSuccess: CodesReducer<
  ReturnType<typeof FuelTypesActions.getFuelTypeSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedFuelType: payload.fuelTypes,
  error: '',
  loading: false,
});

const clearFuelTypes: CodesReducer<any> = () => INITIAL_STATE;

const setFiltersFuelTypes: CodesReducer<
  ReturnType<typeof FuelTypesActions.setFiltersFuelTypes>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const clearSelected: CodesReducer<any> = (state = INITIAL_STATE) => ({
  ...state,
  selectedFuelType: INITIAL_STATE.selectedFuelType,
});

export const reducer = createReducer<FuelTypesState>(INITIAL_STATE, {
  [FuelTypesTypes.GET_FUEL_TYPES_REQUEST]: genericRequest,
  [FuelTypesTypes.GET_FUEL_TYPES_SUCCESS]: getFuelTypesSuccess,
  [FuelTypesTypes.GET_FUEL_TYPES_ERROR]: genericError,
  [FuelTypesTypes.GET_FUEL_TYPE_REQUEST]: genericRequest,
  [FuelTypesTypes.GET_FUEL_TYPE_SUCCESS]: getFuelTypeSuccess,
  [FuelTypesTypes.GET_FUEL_TYPE_ERROR]: genericError,
  [FuelTypesTypes.ADD_FUEL_TYPE_REQUEST]: genericRequest,
  [FuelTypesTypes.ADD_FUEL_TYPE_SUCCESS]: genericSuccess,
  [FuelTypesTypes.ADD_FUEL_TYPE_ERROR]: genericError,
  [FuelTypesTypes.UPDATE_FUEL_TYPE_REQUEST]: genericRequest,
  [FuelTypesTypes.UPDATE_FUEL_TYPE_SUCCESS]: getFuelTypeSuccess,
  [FuelTypesTypes.UPDATE_FUEL_TYPE_ERROR]: genericError,
  [FuelTypesTypes.CLEAR_SELECTED]: clearSelected,
  [FuelTypesTypes.CLEAR_FUEL_TYPES]: clearFuelTypes,
  [FuelTypesTypes.SET_FILTERS_FUEL_TYPES]: setFiltersFuelTypes,
});
