import styled from 'styled-components';

export const Container = styled.label`
  outline: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
`;

type FieldParams = {
  toClipboard?: boolean;
  error?: boolean;
  required?: boolean;
};
export const Field = styled.input<FieldParams>`
  width: 100%;
  height: 100%;
  height: 2.8rem;
  padding: 0 20px;
  font-size: 1rem;
  background-color: ${({ theme }) => theme.colors.gray.base};
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.error : theme.colors.gray.base};
  border-right: ${(props) => props.toClipboard && 'none'};
  border-radius: 100px;
  border-top-right-radius: ${(props) => props.toClipboard && 0};
  border-bottom-right-radius: ${(props) => props.toClipboard && 0};
  box-sizing: border-box;
  outline: none;
  transition: 0.3s background-color;
  &[type='checkbox'] {
    width: auto;
    height: min-content;
    cursor: pointer;
    &:hover {
      box-shadow: none;
    }
    + span {
      position: unset;
    }
  }
  &:focus:not(:read-only) {
    background-color: transparent;
    &:not([type='checkbox']) + span {
      color: ${({ error, theme }) =>
        error ? theme.colors.error : theme.colors.black};
    }
  }
  &:focus,
  &:not(:placeholder-shown) {
    &:not([type='checkbox']) {
      background-color: transparent;
    }
  }
`;
