import { toast } from 'react-toastify';
import i18n from 'i18next';
import { AxiosError } from 'axios';

type GetMessage = {
  message: string;
  params: Record<string, unknown>;
  errors: GetMessage[];
};

function getMessage(messageOriginal: GetMessage): string {
  const params = messageOriginal.params;
  const message = i18n.t(`error.api.${messageOriginal.message}`, {
    ...params,
    defaultValue: messageOriginal.message,
  });
  const others = (messageOriginal.errors || []).map(getMessage);
  return [message, ...others].join('\n');
}

function getErrorMessage(err: AxiosError): string {
  const { response } = err;
  if (!response)
    return 'Erro na conexão com servidor, tente novamente mais tarde.';

  const { error } = response.data;
  const message = typeof error === 'string' ? error : getMessage(error);
  return message;
}

export default function handleError(err: unknown): string {
  const error = getErrorMessage(err as AxiosError);
  toast.error(error, { autoClose: 5000 });
  return error;
}
