import React from 'react';

import { noData } from '@/assets/images';

import { Title } from '@/components/Text';

import { Container } from './styles';

type NoDataProps = {
  message?: string;
};
const NoData: React.FC<NoDataProps> = ({ message }) => {
  const msg = message || 'No Data';
  return (
    <Container>
      <Title>{msg}</Title>
      <img src={noData} alt="teste" width="54%" height="54%" />
    </Container>
  );
};

export default NoData;
