const createFile = (data: string, fileName: string = 'errors.txt'): void => {
  const file = new Blob([data], { type: 'text/plain' });
  const element = document.createElement('a');
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export default createFile;
