import styled, { css } from 'styled-components';
import { ButtonBase } from '@/components/Button';

export const Tabs = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray.base};
  border-radius: 30px;
`;

const tabActive = css`
  background-color: ${(props) => props.theme.colors.primary.base};
  color: #fff;
`;

type TabParams = {
  active: boolean;
};
export const Tab = styled(ButtonBase).attrs({
  type: 'button',
})<TabParams>`
  padding: 0.4rem;
  background-color: transparent;
  border-radius: 30px;
  height: 35px;
  transition: background-color 0.2s ease-in-out;

  ${(props) => props.active && tabActive}
`;
