import { all, takeLatest, call, put } from 'redux-saga/effects';
// import i18next from 'i18next';

import handleError from '@/adapters/httpErrors';
import permissionService from '@/lib/permissions';
import PermissionDTO from '@/dtos/roles/PermissionDTO';
import PermissionsActions, { PermissionsTypes } from './duck';

// function singular(name) {
//   if (name.endsWith('ies')) return name.replace(/ies$/, 'y');
//   return name.replace(/s$/, '');
// }

// function orderByType(permissionsArray) {
//   const permissions = {};

//   permissionsArray.forEach((permission) => {
//     const { name, description, id } = permission;
//     const [type, key, event] = name.split(':');

//     if (!permissions[singular(key)]) permissions[singular(key)] = [];

//     permissions[singular(key)].push({
//       id,
//       description,
//       type,
//       event: event || null,
//     });
//   });
//   const permission = i18next.t('Permissions', { returnObjects: true });
//   const optionsTypes = [
//     {
//       name: 'nothing',
//       excludes: ['show', 'index', 'store', 'update', 'delete'],
//       min: 0,
//       description: permission.nothing,
//     },
//     {
//       name: 'index',
//       excludes: ['store', 'update', 'delete'],
//       min: 1,
//       description: permission.index,
//     },
//     {
//       name: 'store',
//       excludes: ['show', 'index', 'store', 'update', 'delete'],
//       min: 1,
//       description: permission.store,
//     },
//     {
//       name: 'index&store',
//       excludes: ['update', 'delete'],
//       min: 2,
//       description: permission.indexStore,
//     },
//     {
//       name: 'index&update',
//       excludes: ['store', 'delete'],
//       min: 2,
//       description: permission.update,
//     },
//     {
//       name: 'update',
//       excludes: ['store'],
//       min: 3,
//       description: permission.update,
//     },
//     {
//       name: 'delete',
//       excludes: [],
//       min: 5,
//       description: permission.delete,
//     },
//   ];

//   const canAddOption = (type, option) => {
//     return !option.excludes.includes(type);
//   };

//   const getOptions = (currentOption) => {
//     const allOptions = optionsTypes.map((optionType) => {
//       return {
//         name: optionType.name,
//         min: optionType.min,
//         description: optionType.description,
//         values: currentOption.filter((option) =>
//           canAddOption(option.type, optionType)
//         ),
//       };
//     });

//     const filterOptions = allOptions.filter(
//       (option) => option.values.length >= option.min
//     );

//     return filterOptions;
//   };

//   const formatOptions = (moduleName, modulePermissions) => {
//     if (moduleName !== 'event') return getOptions(modulePermissions);

//     return modulePermissions.filter(
//       (perm) =>
//         (perm.type === 'store' && perm.event) ||
//         (perm.type === 'index' && !perm.event)
//     );
//   };

//   const formattedPermissions = Object.keys(permissions).map((item) => ({
//     name: item,
//     options: formatOptions(item, permissions[item]),
//   }));

//   return formattedPermissions;
// }

export function* getPermissions(): Generator {
  try {
    const data = yield call(permissionService.list);
    yield put(
      PermissionsActions.getPermissionsSuccess(data as PermissionDTO[])
    );
  } catch (err) {
    const error = handleError(err);
    yield put(PermissionsActions.getPermissionsError(error));
  }
}

export default all([
  takeLatest(PermissionsTypes.GET_PERMISSIONS_REQUEST, getPermissions),
]);
