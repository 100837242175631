import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'reduxsauce';
import { action } from 'typesafe-actions';

import { PageDTO } from '@/dtos/generics';
import {
  GasStationDTO,
  GasStationFiltersDTO,
  AddGasStationDTO,
  UpdateGasStationDTO,
} from '@/dtos/gasStations';

import { genericError, genericRequest, genericSuccess } from '../utils';
import { GasStationState } from './types';

export enum GasStationsTypes {
  GET_ALL_REQUEST = '@gasStations/getAllRequest',
  GET_ALL_SUCCESS = '@gasStations/getAllSuccess',
  GET_ALL_ERROR = '@gasStations/getAllError',
  GET_ONE_REQUEST = '@gasStations/getOneRequest',
  GET_ONE_SUCCESS = '@gasStations/getOneSuccess',
  GET_ONE_ERROR = '@gasStations/getOneError',
  ADD_REQUEST = '@gasStations/addRequest',
  ADD_SUCCESS = '@gasStations/addSuccess',
  ADD_ERROR = '@gasStations/addError',
  UPDATE_REQUEST = '@gasStations/updateRequest',
  UPDATE_SUCCESS = '@gasStations/updateSuccess',
  UPDATE_ERROR = '@gasStations/updateError',
  SET_FILTERS = '@gasStations/setFilters',
  CLEAR = '@gasStations/clear',
}

const GasStationsActions = {
  getAllRequest: (filters: GasStationFiltersDTO) =>
    action(GasStationsTypes.GET_ALL_REQUEST, { filters }),
  getAllSuccess: (data: PageDTO<GasStationDTO>) =>
    action(GasStationsTypes.GET_ALL_SUCCESS, data),
  getAllError: (error: string) =>
    action(GasStationsTypes.GET_ALL_ERROR, { error }),
  getOneRequest: (id: string) =>
    action(GasStationsTypes.GET_ONE_REQUEST, { id }),
  getOneSuccess: (data: GasStationDTO) =>
    action(GasStationsTypes.GET_ONE_SUCCESS, { data }),
  getOneError: (error: string) =>
    action(GasStationsTypes.GET_ONE_ERROR, { error }),
  addRequest: (data: AddGasStationDTO) =>
    action(GasStationsTypes.ADD_REQUEST, { data }),
  addSuccess: () => action(GasStationsTypes.ADD_SUCCESS),
  addError: (error: string) => action(GasStationsTypes.ADD_ERROR, { error }),
  updateRequest: (data: UpdateGasStationDTO) =>
    action(GasStationsTypes.UPDATE_REQUEST, data),
  updateSuccess: () => action(GasStationsTypes.UPDATE_SUCCESS),
  updateError: (error: string) =>
    action(GasStationsTypes.UPDATE_ERROR, { error }),
  setFilters: (filters: GasStationFiltersDTO) =>
    action(GasStationsTypes.SET_FILTERS, { filters }),
  clear: () => action(GasStationsTypes.CLEAR),
};
export default GasStationsActions;

const INITIAL_STATE: GasStationState = {
  data: [],
  selected: {
    id: '',
    name: '',
    document: '',
    distributorId: '',
    distributor: {},
    createdAt: new Date(),
    updatedAt: new Date(),
  } as GasStationDTO,
  currentPage: 1,
  filters: {},
  previous: undefined,
  next: undefined,
  total: 0,
  error: '',
  loading: false,
};

type GasStationReducer<Action extends AnyAction> = Reducer<
  GasStationState,
  Action
>;

const getAllSuccess: GasStationReducer<
  ReturnType<typeof GasStationsActions.getAllSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  previous: payload.prev,
  next: payload.next,
  total: payload.total,
  error: '',
  loading: false,
});

const getOneSuccess: GasStationReducer<
  ReturnType<typeof GasStationsActions.getOneSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload.data,
  loading: false,
});

const setFilters: GasStationReducer<
  ReturnType<typeof GasStationsActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const clear: GasStationReducer<ReturnType<typeof GasStationsActions.clear>> = (
  state = INITIAL_STATE
) => ({ ...state, selected: INITIAL_STATE.selected });

export const reducer = createReducer(INITIAL_STATE, {
  [GasStationsTypes.GET_ALL_REQUEST]: genericRequest,
  [GasStationsTypes.GET_ALL_SUCCESS]: getAllSuccess,
  [GasStationsTypes.GET_ALL_ERROR]: genericError,
  [GasStationsTypes.GET_ONE_REQUEST]: genericRequest,
  [GasStationsTypes.GET_ONE_SUCCESS]: getOneSuccess,
  [GasStationsTypes.GET_ONE_ERROR]: genericError,
  [GasStationsTypes.ADD_REQUEST]: genericRequest,
  [GasStationsTypes.ADD_SUCCESS]: getAllSuccess,
  [GasStationsTypes.ADD_ERROR]: genericError,
  [GasStationsTypes.UPDATE_REQUEST]: genericRequest,
  [GasStationsTypes.UPDATE_SUCCESS]: genericSuccess,
  [GasStationsTypes.UPDATE_ERROR]: genericError,
  [GasStationsTypes.SET_FILTERS]: setFilters,
  [GasStationsTypes.CLEAR]: clear,
});
