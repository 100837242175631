import AddDistributorFuelDTO from '@/dtos/distributorFuels/AddDistributorFuelDTO';
import DistributorFuelDTO from '@/dtos/distributorFuels/DistributorFuelDTO';
import DistributorFuelFiltersDTO from '@/dtos/distributorFuels/DistributorFuelFilterDTO';
import { PageDTO } from '@/dtos/generics';
import api from '@/services/api';

class DistributorFuelServices {
  async list(
    filters: DistributorFuelFiltersDTO
  ): Promise<PageDTO<DistributorFuelDTO>> {
    const { data } = await api.get<PageDTO<DistributorFuelDTO>>(
      '/distributor-fuels',
      {
        params: filters,
      }
    );
    return data;
  }
  async find(distributorFuelId: string): Promise<DistributorFuelDTO> {
    const { data } = await api.get<DistributorFuelDTO>(
      `/distributor-fuels/${distributorFuelId}`
    );
    return data;
  }
  async create(
    distributorFuel: AddDistributorFuelDTO
  ): Promise<DistributorFuelDTO> {
    const { data } = await api.post<DistributorFuelDTO>(
      '/distributor-fuels',
      distributorFuel
    );
    return data;
  }
  async update(
    distributorFuel: DistributorFuelDTO
  ): Promise<DistributorFuelDTO> {
    const { data } = await api.put<DistributorFuelDTO>(
      `/distributor-fuels/${distributorFuel.id}`,
      distributorFuel
    );
    return data;
  }
}

const distributorFuelServices = new DistributorFuelServices();

export default distributorFuelServices;
