import { AnyAction, Reducer } from 'redux';
import { createReducer } from 'reduxsauce';
import { action } from 'typesafe-actions';

import AddCatalystDTO from '@/dtos/catalysts/AddCatalystDTO';
import CatalystDTO from '@/dtos/catalysts/CatalystDTO';
import CatalystFilterDTO from '@/dtos/catalysts/CatalystFilterDTO';
import { PageDTO } from '@/dtos/generics';

import { genericError, genericRequest, genericSuccess } from '../utils';
import { CatalystsState } from './types';

export enum CatalystsTypes {
  GET_CATALYSTS_REQUEST = '@catalysts/getCatalystsRequest',
  GET_CATALYSTS_SUCCESS = '@catalysts/getCatalystsSuccess',
  GET_CATALYSTS_ERROR = '@catalysts/getCatalystsError',
  GET_CATALYST_REQUEST = '@catalysts/getCatalystRequest',
  GET_CATALYST_SUCCESS = '@catalysts/getCatalystSuccess',
  GET_CATALYST_ERROR = '@catalysts/getCatalystError',
  ADD_CATALYST_REQUEST = '@catalysts/addCatalystRequest',
  ADD_CATALYST_SUCCESS = '@catalysts/addCatalystSuccess',
  ADD_CATALYST_ERROR = '@catalysts/addCatalystError',
  UPDATE_CATALYST_REQUEST = '@catalysts/updateCatalystRequest',
  UPDATE_CATALYST_SUCCESS = '@catalysts/updateCatalystSuccess',
  UPDATE_CATALYST_ERROR = '@catalysts/updateCatalystError',
  SET_FILTERS_CATALYSTS = '@catalysts/setFiltersCatalysts',
  CLEAR_CATALYSTS = '@catalysts/clearCatalysts',
}

const CatalystsActions = {
  getCatalystsRequest: (filters: CatalystFilterDTO) =>
    action(CatalystsTypes.GET_CATALYSTS_REQUEST, { filters }),
  getCatalystsSuccess: (data: PageDTO<CatalystDTO>) =>
    action(CatalystsTypes.GET_CATALYSTS_SUCCESS, data),
  getCatalystsError: (error: string) =>
    action(CatalystsTypes.GET_CATALYSTS_ERROR, { error }),
  getCatalystRequest: (id: string) =>
    action(CatalystsTypes.GET_CATALYST_REQUEST, { id }),
  getCatalystSuccess: (catalyst: CatalystDTO) =>
    action(CatalystsTypes.GET_CATALYST_SUCCESS, { catalyst }),
  getCatalystError: (error: string) =>
    action(CatalystsTypes.GET_CATALYST_ERROR, { error }),
  addCatalystRequest: (data: AddCatalystDTO) =>
    action(CatalystsTypes.ADD_CATALYST_REQUEST, { data }),
  addCatalystSuccess: () => action(CatalystsTypes.ADD_CATALYST_SUCCESS),
  addCatalystError: (error: string) =>
    action(CatalystsTypes.ADD_CATALYST_ERROR, { error }),
  updateCatalystRequest: (data: CatalystDTO) =>
    action(CatalystsTypes.UPDATE_CATALYST_REQUEST, data),
  updateCatalystSuccess: (catalyst: CatalystDTO) =>
    action(CatalystsTypes.UPDATE_CATALYST_SUCCESS, { catalyst }),
  updateCatalystError: (error: string) =>
    action(CatalystsTypes.UPDATE_CATALYST_ERROR, { error }),
  setFiltersCatalysts: (filters: CatalystFilterDTO) =>
    action(CatalystsTypes.SET_FILTERS_CATALYSTS, { filters }),
  clearCatalysts: () => action(CatalystsTypes.CLEAR_CATALYSTS),
};
export default CatalystsActions;

const INITIAL_STATE: CatalystsState = {
  data: [],
  selectedCatalyst: {} as CatalystDTO,
  currentPage: 1,
  filters: {},
  prev: undefined,
  next: undefined,
  total: 0,
  error: '',
  loading: false,
};

export type CatalystReducer<Action extends AnyAction> = Reducer<
  CatalystsState,
  Action
>;

const getCatalystsSuccess: CatalystReducer<
  ReturnType<typeof CatalystsActions.getCatalystsSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  error: '',
  loading: false,
});

const getCatalystSelect: CatalystReducer<
  ReturnType<typeof CatalystsActions.getCatalystSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedCatalyst: payload.catalyst,
  error: '',
  loading: false,
});

const clearCatalysts: CatalystReducer<any> = () => INITIAL_STATE;

const setFiltersCatalysts: CatalystReducer<
  ReturnType<typeof CatalystsActions.setFiltersCatalysts>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

export const reducer = createReducer<CatalystsState>(INITIAL_STATE, {
  [CatalystsTypes.GET_CATALYSTS_REQUEST]: genericRequest,
  [CatalystsTypes.GET_CATALYSTS_SUCCESS]: getCatalystsSuccess,
  [CatalystsTypes.GET_CATALYSTS_ERROR]: genericError,
  [CatalystsTypes.GET_CATALYST_REQUEST]: genericRequest,
  [CatalystsTypes.GET_CATALYST_SUCCESS]: getCatalystSelect,
  [CatalystsTypes.GET_CATALYST_ERROR]: genericError,
  [CatalystsTypes.ADD_CATALYST_REQUEST]: genericRequest,
  [CatalystsTypes.ADD_CATALYST_SUCCESS]: genericSuccess,
  [CatalystsTypes.ADD_CATALYST_ERROR]: genericError,
  [CatalystsTypes.UPDATE_CATALYST_REQUEST]: genericRequest,
  [CatalystsTypes.UPDATE_CATALYST_SUCCESS]: getCatalystSelect,
  [CatalystsTypes.UPDATE_CATALYST_ERROR]: genericError,
  [CatalystsTypes.CLEAR_CATALYSTS]: clearCatalysts,
  [CatalystsTypes.SET_FILTERS_CATALYSTS]: setFiltersCatalysts,
});
