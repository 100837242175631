import { createReducer } from 'reduxsauce';
import { action } from 'typesafe-actions';

import { PageDTO } from '@/dtos/generics';
import {
  AddSubsidiaryDTO,
  SubsidiaryDTO,
  SubsidiaryFilterDTO,
} from '@/dtos/subsidiaries';
import { SubsidiaryState } from './types';
import { genericError, genericRequest, genericSuccess } from '../utils';
import { AnyAction, Reducer } from 'redux';

export enum SubsidiariesTypes {
  GET_SUBSIDIARIES_REQUEST = '@subsidiaries/getSubsidiariesRequest',
  GET_SUBSIDIARIES_SUCCESS = '@subsidiaries/getSubsidiariesSuccess',
  GET_SUBSIDIARIES_ERROR = '@subsidiaries/getSubsidiariesError',
  GET_SUBSIDIARY_REQUEST = '@subsidiaries/getSubsidiaryRequest',
  GET_SUBSIDIARY_SUCCESS = '@subsidiaries/getSubsidiarySuccess',
  GET_SUBSIDIARY_ERROR = '@subsidiaries/getSubsidiaryError',
  ADD_SUBSIDIARY_REQUEST = '@subsidiaries/addSubsidiaryRequest',
  ADD_SUBSIDIARY_SUCCESS = '@subsidiaries/addSubsidiarySuccess',
  ADD_SUBSIDIARY_ERROR = '@subsidiaries/addSubsidiaryError',
  UPDATE_SUBSIDIARY_REQUEST = '@subsidiaries/updateSubsidiaryRequest',
  UPDATE_SUBSIDIARY_SUCCESS = '@subsidiaries/updateSubsidiarySuccess',
  UPDATE_SUBSIDIARY_ERROR = '@subsidiaries/updateSubsidiaryError',
  SET_FILTERS_SUBSIDIARIES = '@subsidiaries/setFiltersSubsidiaries',
  CLEAR_SUBSIDIARY = '@subsidiaries/clearSubsidiary',
}
const SubsidiariesActions = {
  getSubsidiariesRequest: (filters: SubsidiaryFilterDTO) =>
    action(SubsidiariesTypes.GET_SUBSIDIARIES_REQUEST, { filters }),
  getSubsidiariesSuccess: (data: PageDTO<SubsidiaryDTO>) =>
    action(SubsidiariesTypes.GET_SUBSIDIARIES_SUCCESS, data),
  getSubsidiariesError: (error: string) =>
    action(SubsidiariesTypes.GET_SUBSIDIARIES_ERROR, { error }),
  getSubsidiaryRequest: (id: string) =>
    action(SubsidiariesTypes.GET_SUBSIDIARY_REQUEST, { id }),
  getSubsidiarySuccess: (data: SubsidiaryDTO) =>
    action(SubsidiariesTypes.GET_SUBSIDIARY_SUCCESS, { data }),
  getSubsidiaryError: (error: string) =>
    action(SubsidiariesTypes.GET_SUBSIDIARY_ERROR, { error }),
  addSubsidiaryRequest: (data: AddSubsidiaryDTO) =>
    action(SubsidiariesTypes.ADD_SUBSIDIARY_REQUEST, { data }),
  addSubsidiarySuccess: () => action(SubsidiariesTypes.ADD_SUBSIDIARY_SUCCESS),
  addSubsidiaryError: (error: string) =>
    action(SubsidiariesTypes.ADD_SUBSIDIARY_ERROR, { error }),
  updateSubsidiaryRequest: (data: SubsidiaryDTO) =>
    action(SubsidiariesTypes.UPDATE_SUBSIDIARY_REQUEST, { data }),
  updateSubsidiarySuccess: () =>
    action(SubsidiariesTypes.UPDATE_SUBSIDIARY_SUCCESS),
  updateSubsidiaryError: (error: string) =>
    action(SubsidiariesTypes.UPDATE_SUBSIDIARY_ERROR, { error }),
  setFiltersSubsidiaries: (filters: SubsidiaryFilterDTO) =>
    action(SubsidiariesTypes.SET_FILTERS_SUBSIDIARIES, { filters }),
  clearSubsidiary: () => action(SubsidiariesTypes.CLEAR_SUBSIDIARY),
};
export default SubsidiariesActions;

const INITIAL_STATE: SubsidiaryState = {
  data: [],
  selected: {} as SubsidiaryDTO,
  currentPage: 1,
  filters: {},
  prev: undefined,
  next: undefined,
  total: 0,
  error: '',
  loading: false,
};

type SubsidiaryReducer<Action extends AnyAction> = Reducer<
  SubsidiaryState,
  Action
>;

const getSubsidiarySuccess: SubsidiaryReducer<
  ReturnType<typeof SubsidiariesActions.getSubsidiarySuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload.data,
  loading: false,
});

const getSubsidiariesSuccess: SubsidiaryReducer<
  ReturnType<typeof SubsidiariesActions.getSubsidiariesSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  prev: payload.prev,
  next: payload.next,
  total: payload.total,
  error: '',
  loading: false,
});

const clearSubsidiary: SubsidiaryReducer<
  ReturnType<typeof SubsidiariesActions.clearSubsidiary>
> = (state = INITIAL_STATE) => ({ ...state, selected: INITIAL_STATE.selected });

const setFiltersSubsidiaries: SubsidiaryReducer<
  ReturnType<typeof SubsidiariesActions.setFiltersSubsidiaries>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

export const reducer = createReducer<SubsidiaryState>(INITIAL_STATE, {
  [SubsidiariesTypes.GET_SUBSIDIARIES_REQUEST]: genericRequest,
  [SubsidiariesTypes.GET_SUBSIDIARIES_SUCCESS]: getSubsidiariesSuccess,
  [SubsidiariesTypes.GET_SUBSIDIARIES_ERROR]: genericError,
  [SubsidiariesTypes.GET_SUBSIDIARY_REQUEST]: genericRequest,
  [SubsidiariesTypes.GET_SUBSIDIARY_SUCCESS]: getSubsidiarySuccess,
  [SubsidiariesTypes.GET_SUBSIDIARY_ERROR]: genericError,
  [SubsidiariesTypes.ADD_SUBSIDIARY_REQUEST]: genericRequest,
  [SubsidiariesTypes.ADD_SUBSIDIARY_SUCCESS]: genericSuccess,
  [SubsidiariesTypes.ADD_SUBSIDIARY_ERROR]: genericError,
  [SubsidiariesTypes.UPDATE_SUBSIDIARY_REQUEST]: genericRequest,
  [SubsidiariesTypes.UPDATE_SUBSIDIARY_SUCCESS]: getSubsidiarySuccess,
  [SubsidiariesTypes.UPDATE_SUBSIDIARY_ERROR]: genericError,
  [SubsidiariesTypes.CLEAR_SUBSIDIARY]: clearSubsidiary,
  [SubsidiariesTypes.SET_FILTERS_SUBSIDIARIES]: setFiltersSubsidiaries,
});
