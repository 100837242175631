import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';
// import api from '@/services/api';
import history from '@/services/history';
import handleError from '@/adapters/httpErrors';
import fuelTypeServices from '@/lib/fuelTypes';
import { PageDTO } from '@/dtos/generics';
import { FuelTypeDTO } from '@/dtos/fuelTypes';

import FuelTypesActions, { FuelTypesTypes } from './duck';

const fuelTypeAdd = i18next.t('Success.fuelTypeAdd');
const fuelTypeUpdate = i18next.t('Success.fuelTypeUpdate');

type GetFuelTypesParams = ReturnType<
  typeof FuelTypesActions.getFuelTypesRequest
>;
export function* getFuelTypes({ payload }: GetFuelTypesParams): Generator {
  try {
    const data = yield call(fuelTypeServices.list, payload.filters);
    yield put(
      FuelTypesActions.getFuelTypesSuccess(data as PageDTO<FuelTypeDTO>)
    );
  } catch (err) {
    const error = handleError(err);
    yield put(FuelTypesActions.getFuelTypesError(error));
  }
}

type GetFuelTypeParams = ReturnType<typeof FuelTypesActions.getFuelTypeRequest>;
export function* getFuelType({ payload }: GetFuelTypeParams): Generator {
  try {
    const data = yield call(fuelTypeServices.find, payload.id);
    yield put(FuelTypesActions.getFuelTypeSuccess(data as FuelTypeDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(FuelTypesActions.getFuelTypeError(error));
  }
}

type AddFuelTypeParams = ReturnType<typeof FuelTypesActions.addFuelTypeRequest>;
export function* addFuelType({ payload }: AddFuelTypeParams): Generator {
  try {
    yield call(fuelTypeServices.create, payload.data);
    yield put(FuelTypesActions.addFuelTypeSuccess());
    toast.success(fuelTypeAdd);
    history.push('/fuel-types');
  } catch (err) {
    const error = handleError(err);
    yield put(FuelTypesActions.addFuelTypeError(error));
  }
}

type UpdateFuelTypeParams = ReturnType<
  typeof FuelTypesActions.updateFuelTypeRequest
>;
export function* updateFuelType({ payload }: UpdateFuelTypeParams): Generator {
  try {
    const { data } = payload;
    const fuelType = yield call(fuelTypeServices.update, data);
    yield put(FuelTypesActions.updateFuelTypeSuccess(fuelType as FuelTypeDTO));
    yield put(FuelTypesActions.getFuelTypesRequest({}));
    yield put(FuelTypesActions.getFuelTypeRequest(data.id));
    toast.success(fuelTypeUpdate);
    history.push('/fuel-types');
  } catch (err) {
    const error = handleError(err);
    yield put(FuelTypesActions.updateFuelTypeError(error));
  }
}

export default all([
  takeLatest(FuelTypesTypes.GET_FUEL_TYPES_REQUEST, getFuelTypes),
  takeLatest(FuelTypesTypes.GET_FUEL_TYPE_REQUEST, getFuelType),
  takeLatest(FuelTypesTypes.ADD_FUEL_TYPE_REQUEST, addFuelType),
  takeLatest(FuelTypesTypes.UPDATE_FUEL_TYPE_REQUEST, updateFuelType),
]);
