import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';
import subsidiaryServices from '@/lib/subsidiaries';
import history from '@/services/history';
import handleError from '@/adapters/httpErrors';
import { PageDTO } from '@/dtos/generics';
import { SubsidiaryDTO } from '@/dtos/subsidiaries';

import SubsidiariesActions, { SubsidiariesTypes } from './duck';

const registerOrganzation = i18next.t('Success.registerOrganzation');
const organzationUpdate = i18next.t('Success.organzationUpdate');

type GetSubsidiariesParams = ReturnType<
  typeof SubsidiariesActions.getSubsidiariesRequest
>;
export function* getSubsidiaries({
  payload,
}: GetSubsidiariesParams): Generator {
  try {
    const data = yield call(subsidiaryServices.list, payload.filters);
    yield put(
      SubsidiariesActions.getSubsidiariesSuccess(data as PageDTO<SubsidiaryDTO>)
    );
  } catch (err) {
    const error = handleError(err);
    yield put(SubsidiariesActions.getSubsidiariesError(error));
  }
}

type GetSubsidiaryParams = ReturnType<
  typeof SubsidiariesActions.getSubsidiaryRequest
>;
export function* getSubsidiary({ payload }: GetSubsidiaryParams): Generator {
  try {
    const data = yield call(subsidiaryServices.find, payload.id);
    yield put(SubsidiariesActions.getSubsidiarySuccess(data as SubsidiaryDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(SubsidiariesActions.getSubsidiaryError(error));
  }
}

type AddSubsidiaryParams = ReturnType<
  typeof SubsidiariesActions.addSubsidiaryRequest
>;
export function* addSubsidiary({ payload }: AddSubsidiaryParams): Generator {
  try {
    yield call(subsidiaryServices.create, payload.data);
    yield put(SubsidiariesActions.addSubsidiarySuccess());
    toast.success(registerOrganzation);
    history.push('/');
  } catch (err) {
    const error = handleError(err);
    yield put(SubsidiariesActions.addSubsidiaryError(error));
  }
}

type UpdateSubsidiaryParams = ReturnType<
  typeof SubsidiariesActions.updateSubsidiaryRequest
>;
export function* updateSubsidiary({
  payload,
}: UpdateSubsidiaryParams): Generator {
  try {
    yield call(subsidiaryServices.update, payload.data);
    yield put(SubsidiariesActions.updateSubsidiarySuccess());
    yield put(SubsidiariesActions.getSubsidiariesRequest({}));
    yield put(SubsidiariesActions.getSubsidiaryRequest(payload.data.id));
    toast.success(organzationUpdate);
  } catch (err) {
    const error = handleError(err);
    yield put(SubsidiariesActions.updateSubsidiaryError(error));
  }
}

export default all([
  takeLatest(SubsidiariesTypes.GET_SUBSIDIARIES_REQUEST, getSubsidiaries),
  takeLatest(SubsidiariesTypes.GET_SUBSIDIARY_REQUEST, getSubsidiary),
  takeLatest(SubsidiariesTypes.UPDATE_SUBSIDIARY_REQUEST, updateSubsidiary),
  takeLatest(SubsidiariesTypes.ADD_SUBSIDIARY_REQUEST, addSubsidiary),
]);
