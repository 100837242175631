import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';
import history from '@/services/history';
import handleError from '@/adapters/httpErrors';
import catalystServices from '@/lib/catalysts';
import { PageDTO } from '@/dtos/generics';
import CatalystDTO from '@/dtos/catalysts/CatalystDTO';

import CatalystsActions, { CatalystsTypes } from './duck';

const catalystAdd = i18next.t('Success.catalystAdd');
const catalystUpdate = i18next.t('Success.catalystUpdate');

type GetCatalystsParams = ReturnType<
  typeof CatalystsActions.getCatalystsRequest
>;
export function* getCatalysts({ payload }: GetCatalystsParams): Generator {
  try {
    const data = yield call(catalystServices.list, payload.filters);
    yield put(
      CatalystsActions.getCatalystsSuccess(data as PageDTO<CatalystDTO>)
    );
  } catch (err) {
    const error = handleError(err);
    yield put(CatalystsActions.getCatalystsError(error));
  }
}

type GetCatalystParams = ReturnType<typeof CatalystsActions.getCatalystRequest>;
export function* getCatalyst({ payload }: GetCatalystParams): Generator {
  try {
    const data = yield call(catalystServices.find, payload.id);
    yield put(CatalystsActions.getCatalystSuccess(data as CatalystDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(CatalystsActions.getCatalystError(error));
  }
}

type AddCatalystParams = ReturnType<typeof CatalystsActions.addCatalystRequest>;
export function* addCatalyst({ payload }: AddCatalystParams): Generator {
  try {
    yield call(catalystServices.create, payload.data);
    yield put(CatalystsActions.addCatalystSuccess());
    toast.success(catalystAdd);
    history.push('/catalysts');
  } catch (err) {
    const error = handleError(err);
    yield put(CatalystsActions.addCatalystError(error));
  }
}

type UpdateCatalystParams = ReturnType<
  typeof CatalystsActions.updateCatalystRequest
>;
export function* updateCatalyst({ payload }: UpdateCatalystParams): Generator {
  try {
    const data = yield call(catalystServices.update, payload);
    yield put(CatalystsActions.updateCatalystSuccess(data as CatalystDTO));
    yield put(CatalystsActions.getCatalystsRequest({}));
    yield put(CatalystsActions.getCatalystRequest(payload.id));
    toast.success(catalystUpdate);
    history.push('/catalysts');
  } catch (err) {
    const error = handleError(err);
    yield put(CatalystsActions.updateCatalystError(error));
  }
}

export default all([
  takeLatest(CatalystsTypes.GET_CATALYSTS_REQUEST, getCatalysts),
  takeLatest(CatalystsTypes.GET_CATALYST_REQUEST, getCatalyst),
  takeLatest(CatalystsTypes.ADD_CATALYST_REQUEST, addCatalyst),
  takeLatest(CatalystsTypes.UPDATE_CATALYST_REQUEST, updateCatalyst),
]);
