import { AnyAction, Reducer } from 'redux';
import { createReducer } from 'reduxsauce';
import { action } from 'typesafe-actions';

import EventDTO from '@/dtos/events/EventDTO';
import EventFilterDTO from '@/dtos/events/EventFilterDTO';

import { genericError, genericRequest } from '../utils';
import { CodesState } from './types';

export enum CodesTypes {
  GET_EVENTS_REQUEST = '@codes/getEventsRequest',
  GET_EVENTS_SUCCESS = '@codes/getEventsSuccess',
  GET_EVENTS_ERROR = '@codes/getEventsError',
  CLEAR_EVENTS = '@codes/clearEvents',
  GET_CODES_BY_SOLICITATION_REQUEST = '@codes/getCodesBySolicitationRequest',
  GET_CODES_BY_SOLICITATION_SUCCESS = '@codes/getCodesBySolicitationSuccess',
  GET_CODES_BY_SOLICITATION_ERROR = '@codes/getCodesBySolicitationError',
  SET_SOLICITATION = '@codes/setSolicitation',
}
const CodesActions = {
  getEventsRequest: (code: string, filters: EventFilterDTO) =>
    action(CodesTypes.GET_EVENTS_REQUEST, { code, filters }),
  getEventsSuccess: (data: EventDTO[]) =>
    action(CodesTypes.GET_EVENTS_SUCCESS, { data }),
  getEventsError: (error: string) =>
    action(CodesTypes.GET_EVENTS_ERROR, { error }),
  clearEvents: () => action(CodesTypes.CLEAR_EVENTS),
  getCodesBySolicitationRequest: (solicitationId: string) =>
    action(CodesTypes.GET_CODES_BY_SOLICITATION_REQUEST, { solicitationId }),
  getCodesBySolicitationSuccess: (data: string[]) =>
    action(CodesTypes.GET_CODES_BY_SOLICITATION_SUCCESS, { data }),
  getCodesBySolicitationError: (error: string) =>
    action(CodesTypes.GET_CODES_BY_SOLICITATION_ERROR, { error }),
};
export default CodesActions;

const INITIAL_STATE: CodesState = {
  codes: [],
  events: [],
  loading: false,
  error: '',
};

export type CodesReducer<Action extends AnyAction> = Reducer<
  CodesState,
  Action
>;

const getCodesBySolicitationSuccess: CodesReducer<
  ReturnType<typeof CodesActions.getCodesBySolicitationSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  codes: payload.data,
  loading: false,
  error: '',
});

const getEventsSuccess: CodesReducer<
  ReturnType<typeof CodesActions.getEventsSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  events: payload.data,
  loading: false,
  error: '',
});

const clearEvents: CodesReducer<
  ReturnType<typeof CodesActions.clearEvents>
> = () => INITIAL_STATE;

export const reducer = createReducer<CodesState>(INITIAL_STATE, {
  [CodesTypes.GET_EVENTS_REQUEST]: genericRequest,
  [CodesTypes.GET_EVENTS_SUCCESS]: getEventsSuccess,
  [CodesTypes.GET_EVENTS_ERROR]: genericError,
  [CodesTypes.CLEAR_EVENTS]: clearEvents,
  [CodesTypes.GET_CODES_BY_SOLICITATION_REQUEST]: genericRequest,
  [CodesTypes.GET_CODES_BY_SOLICITATION_SUCCESS]: getCodesBySolicitationSuccess,
  [CodesTypes.GET_CODES_BY_SOLICITATION_ERROR]: genericError,
});
