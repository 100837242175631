import React from 'react';

import { LoaderContainer, LoaderImage } from './styles';

const Loader: React.FC = () => {
  return (
    <LoaderContainer>
      <LoaderImage />
    </LoaderContainer>
  );
};

export default Loader;
