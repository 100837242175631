import api from '@/services/api';

class CodeServices {
  async findBySolicitation(solicitationId: string): Promise<string[]> {
    const { data } = await api.get<string[]>(
      `/solicitations/${solicitationId}/codes`,
      {
        params: { type: 'CREATION' },
      }
    );
    return data;
  }
}

const codeServices = new CodeServices();

export default codeServices;
