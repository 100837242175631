import { AnyAction, Reducer } from 'redux';
import { createReducer } from 'reduxsauce';
import { action } from 'typesafe-actions';

import AddEventDTO from '@/dtos/events/AddEventDTO';
import { EventsState } from './types';

export enum EventsTypes {
  ADD_EVENT_REQUEST = '@events/addEventRequest',
  ADD_EVENT_SUCCESS = '@events/addEventSuccess',
  ADD_EVENT_ERROR = '@events/addEventError',
  BREAKDOWN_REQUEST = '@events/breakdownRequest',
  BREAKDOWN_SUCCESS = '@events/breakdownSuccess',
  BREAKDOWN_ERROR = '@events/breakdownError',
  PRINT_EVENT_REQUEST = '@events/printEventRequest',
  PRINT_PARTITION_REQUEST = '@events/printPartitionRequest',
  PRINT_VOLUME_REQUEST = '@events/printVolumeRequest',
  RE_PRINT_EVENT_REQUEST = '@events/rePrintEventRequest',
  TEST_PRINT_EVENT_REQUEST = '@events/testPrintEventRequest',
  PRINT_EVENT_SUCCESS = '@events/printEventSuccess',
  PRINT_EVENT_ERROR = '@events/printEventError',
  CLEAR_ERRORS = '@events/clearErrors',
}
const EventsActions = {
  addEventRequest: (data: AddEventDTO[], codes: string[]) =>
    action(EventsTypes.ADD_EVENT_REQUEST, { data, codes }),
  addEventSuccess: () => action(EventsTypes.ADD_EVENT_SUCCESS),
  addEventError: (errors: string[]) =>
    action(EventsTypes.ADD_EVENT_ERROR, { errors }),
  breakdownRequest: (data: AddEventDTO[], codes: string[]) =>
    action(EventsTypes.BREAKDOWN_REQUEST, { data, codes }),
  breakdownSuccess: () => action(EventsTypes.BREAKDOWN_SUCCESS),
  breakdownError: (errors: string[]) =>
    action(EventsTypes.BREAKDOWN_ERROR, { errors }),
  printEventRequest: (data: AddEventDTO[], resetForm: () => void) =>
    action(EventsTypes.PRINT_EVENT_REQUEST, { data, resetForm }),
  printPartitionRequest: (data: AddEventDTO[], resetForm: () => void) =>
    action(EventsTypes.PRINT_PARTITION_REQUEST, { data, resetForm }),
  printVolumeRequest: (data: AddEventDTO[], resetForm: () => void) =>
    action(EventsTypes.PRINT_VOLUME_REQUEST, { data, resetForm }),
  rePrintEventRequest: (data: AddEventDTO[]) =>
    action(EventsTypes.RE_PRINT_EVENT_REQUEST, { data }),
  printEventSuccess: () => action(EventsTypes.PRINT_EVENT_SUCCESS),
  printEventError: (errors: string[]) =>
    action(EventsTypes.PRINT_EVENT_ERROR, { errors }),
  clearErrors: () => action(EventsTypes.CLEAR_ERRORS),
};
export default EventsActions;

const INITIAL_STATE: EventsState = {
  loading: false,
  errors: [],
};

type EventsReducer<Action extends AnyAction> = Reducer<EventsState, Action>;

const genericSuccess: EventsReducer<any> = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  errors: [],
});

const genericRequest: EventsReducer<any> = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const genericError: EventsReducer<
  ReturnType<typeof EventsActions.addEventError>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: false,
  errors: payload.errors,
});

export const reducer = createReducer<EventsState>(INITIAL_STATE, {
  [EventsTypes.ADD_EVENT_REQUEST]: genericRequest,
  [EventsTypes.ADD_EVENT_SUCCESS]: genericSuccess,
  [EventsTypes.ADD_EVENT_ERROR]: genericError,
  [EventsTypes.BREAKDOWN_REQUEST]: genericRequest,
  [EventsTypes.BREAKDOWN_SUCCESS]: genericSuccess,
  [EventsTypes.BREAKDOWN_ERROR]: genericError,
  [EventsTypes.CLEAR_ERRORS]: genericSuccess,
  [EventsTypes.PRINT_EVENT_REQUEST]: genericRequest,
  [EventsTypes.PRINT_PARTITION_REQUEST]: genericRequest,
  [EventsTypes.PRINT_VOLUME_REQUEST]: genericRequest,
  [EventsTypes.RE_PRINT_EVENT_REQUEST]: genericRequest,
});
