import styled from 'styled-components';

import { ButtonProps } from './types';

export const ButtonBase = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.3s;
  &:focus,
  &:hover {
    outline: none;
  }
`;

export const Button = styled(ButtonBase)<ButtonProps>`
  width: 100%;
  height: 2.8rem;
  color: ${({ secondary, theme }) =>
    secondary ? theme.colors.primary.base : theme.colors.white};
  background-color: ${({ secondary, theme }) =>
    secondary ? 'transparent' : theme.colors.primary.base};
  border: ${({ secondary, theme }) =>
    secondary ? `1px solid ${theme.colors.primary.light}` : 'none'};
  border-radius: 100px;
  transition: color 0.3s, background-color 0.5s;
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: ${({ secondary, theme }) =>
      secondary ? theme.colors.primary.light : theme.colors.primary.dark};
    border: ${({ secondary, theme }) =>
      secondary ? `1px solid ${theme.colors.primary.light}` : 'none'};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray.base};
    color: ${({ theme }) => theme.colors.primary.light};
    cursor: not-allowed;
  }
`;

export const IconButton = styled(ButtonBase)`
  padding: 0;
`;
