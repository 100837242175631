import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';
import createFile from '@/utils/createFile';
import eventServices from '@/lib/events';
import EventErrorDTO from '@/dtos/events/EventErrorDTO';
import EventsActions, { EventsTypes } from './duck';

const moveError = i18next.t('Success.moveError');
const moveSuccess = i18next.t('Success.moveSuccess');
const erro = i18next.t('Success.error');
const inCode = i18next.t('Success.inCode');

type AddEventParams = ReturnType<typeof EventsActions.addEventRequest>;
export function* addEvent({ payload }: AddEventParams): Generator {
  try {
    const { codes } = payload;
    const result = yield call(eventServices.create, payload.data);
    const data = result as EventErrorDTO[];

    if (data.length) {
      toast.error(moveError);
      const errors = data.map(
        (err) => `${erro} ${i18next.t(err.code)} ${inCode} ${codes[err.line]}`
      );

      createFile(errors.join('\n'));
      yield put(EventsActions.addEventError(errors));
      return;
    }

    yield put(EventsActions.addEventSuccess());
    toast.success(moveSuccess);
  } catch (err) {
    // const error = handleError(err);
    yield put(EventsActions.addEventError(err as string[]));
  }
}

type BreakdownEventParams = ReturnType<typeof EventsActions.breakdownRequest>;
export function* breakdownEvent({ payload }: BreakdownEventParams): Generator {
  try {
    const { codes } = payload;
    const result = yield call(eventServices.breakdown, payload.data);
    const data = result as EventErrorDTO[];

    if (data.length) {
      toast.error(moveError);
      const errors = data.map(
        (err) => `${erro} ${i18next.t(err.code)} ${inCode} ${codes[err.line]}`
      );

      createFile(errors.join('\n'));
      yield put(EventsActions.breakdownError(errors));
      return;
    }

    yield put(EventsActions.breakdownSuccess());
    toast.success(moveSuccess);
  } catch (err) {
    // const error = handleError(err);
    yield put(EventsActions.breakdownError(err as string[]));
  }
}

export default all([
  takeLatest(EventsTypes.ADD_EVENT_REQUEST, addEvent),
  takeLatest(EventsTypes.BREAKDOWN_REQUEST, breakdownEvent),
]);
