import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';
import { history } from '@/services';
import { httpErrors } from '@/adapters';
import { gasStationsServices } from '@/lib';

import { PageDTO } from '@/dtos/generics';
import { GasStationDTO } from '@/dtos/gasStations';

import GasStationsActions, { GasStationsTypes } from './duck';

const gasStationAdd = i18next.t('success.gasStationAdd');
const gasStationUpdate = i18next.t('success.gasStationUpdate');

type GetAllParams = ReturnType<typeof GasStationsActions.getAllRequest>;
export function* getAll({ payload }: GetAllParams): Generator {
  try {
    const data = yield call(gasStationsServices.list, payload.filters);
    yield put(GasStationsActions.getAllSuccess(data as PageDTO<GasStationDTO>));
  } catch (err) {
    const error = httpErrors(err);
    yield put(GasStationsActions.getAllError(error));
  }
}

type GetOneParams = ReturnType<typeof GasStationsActions.getOneRequest>;
export function* getOne({ payload }: GetOneParams): Generator {
  try {
    const data = yield call(gasStationsServices.find, payload.id);
    yield put(GasStationsActions.getOneSuccess(data as GasStationDTO));
  } catch (err) {
    const error = httpErrors(err);
    yield put(GasStationsActions.getOneError(error));
  }
}

type AddParams = ReturnType<typeof GasStationsActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(gasStationsServices.save, payload.data);
    yield put(GasStationsActions.addSuccess());
    history.push('/gas-stations');
    toast.success(gasStationAdd);
  } catch (err) {
    const error = httpErrors(err);
    yield put(GasStationsActions.addError(error));
  }
}

type UpdateParams = ReturnType<typeof GasStationsActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(gasStationsServices.update, payload);
    yield put(GasStationsActions.updateSuccess());
    yield put(GasStationsActions.getAllRequest({}));
    yield put(GasStationsActions.getOneRequest(payload.id));
    toast.success(gasStationUpdate);
    history.push('/gas-stations');
  } catch (err) {
    const error = httpErrors(err);
    yield put(GasStationsActions.updateError(error));
  }
}

export default all([
  takeLatest(GasStationsTypes.GET_ALL_REQUEST, getAll),
  takeLatest(GasStationsTypes.GET_ONE_REQUEST, getOne),
  takeLatest(GasStationsTypes.ADD_REQUEST, add),
  takeLatest(GasStationsTypes.UPDATE_REQUEST, update),
]);
