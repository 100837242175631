import api from '@/services/api';
import CreateDriverWork from '@/dtos/driverWorks/CreateDriverWork';

class DriverWorks {
  async create(data: CreateDriverWork): Promise<void> {
    await api.post('/drivers/works', data);
  }
}

const driverWorks = new DriverWorks();

export default driverWorks;
