export type Position = {
  latitude: number;
  longitude: number;
};

class PositionProvider {
  getCurrentPosition(): Promise<Position> {
    return new Promise((resolve) =>
      navigator.geolocation.getCurrentPosition(({ coords }) => resolve(coords))
    );
  }
}

const positionProvider = new PositionProvider();

export default positionProvider;
