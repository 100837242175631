import styled from 'styled-components';

import { Title } from '@/components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  margin: 1.5rem 3.75rem 1.8rem;
`;

export const TitleSection = styled.section`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, auto);
  column-gap: 20px;
  align-items: center;
`;

export const HeaderTitle = styled(Title)`
  font-size: 28px;
  color: ${({ theme }) => theme.colors.black};
  line-height: 35px;
  span {
    font-size: 22px;
  }
`;

export const ActionsSection = styled.div`
  position: relative;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;
